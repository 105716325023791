/**
 * Created by Lasse on 2/10/2018.
 */
import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class WindowManager {
	public static BREAKPOINT_MOBILE = 640;
	public static BREAKPOINT_TABLET_PORTRAIT = 768;
	public static BREAKPOINT_TABLET = 1024;
	public static BREAKPOINT_DESKTOP = 1440;

	public static signalResize: Signal = new Signal();
	public static width: number;
	public static height: number;
	public static halfWidth: number;
	public static halfHeight: number;
	public static documentWidth: number;
	public static documentHeight: number;
	public static scaleFactor: number;

	private static previousHeight: number;
	private static instance: WindowManager;

	private constructor() {
		window.addEventListener('resize', (event: Event) => this.resizeHandler(event));
		this.resizeHandler();
	}

	public static getInstance(): WindowManager {
		if (!WindowManager.instance) {
			WindowManager.instance = new WindowManager();
		}

		return WindowManager.instance;
	}

	public static aspectRatio(): number {
		return WindowManager.width / WindowManager.height;
	}

	public static isMobile(): boolean {
		return WindowManager.width <= WindowManager.BREAKPOINT_MOBILE;
	}

	public sendFakeResizeEvent(): void {
		this.resizeHandler();
	}

	private resizeHandler(event?: Event): void {
		WindowManager.width = window.innerWidth;
		WindowManager.height = window.innerHeight;

		WindowManager.halfWidth = Math.round(WindowManager.width * 0.5);
		WindowManager.halfHeight = Math.round(WindowManager.height * 0.5);

		WindowManager.documentWidth = document.documentElement.clientWidth;
		WindowManager.documentHeight = document.documentElement.clientHeight;

		WindowManager.scaleFactor = WindowManager.width / 1440;

		WindowManager.previousHeight = WindowManager.height;

		WindowManager.signalResize.dispatch(WindowManager.width, WindowManager.height);
	}
}

declare global {
	interface Window {
		gtag: any;
		gtagId: any;
	}
}

import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { WindowManager } from '../utils/WindowManager';
import { Globals } from '../data/Globals';

export interface MouseInputData {
	x: number;
	y: number;
	xPercent: number;
	yPercent: number;
	centerPercentX: number;
	centerPercentY: number;
	target: any;
}

export class MouseInput extends Signal {
	constructor() {
		super();

		if (!Globals.IS_TOUCH_DEVICE) {
			window.addEventListener('mousemove', this.onMove);
		}
	}

	private onMove = e => {
		if (this.getNumListeners() > 0) {
			let x = e.pageX;
			let y = e.pageY;
			let xPercent = x / WindowManager.width;
			let yPercent = y / WindowManager.height;

			this.dispatch({
				x: x,
				y: y,
				xPercent: xPercent,
				yPercent: yPercent,
				centerPercentX: (xPercent - 0.5) * 2,
				centerPercentY: (yPercent - 0.5) * 2,
				target: e.target
			});
		}
	};
}

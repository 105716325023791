import { Globals } from './../data/Globals';
import Dat from 'dat.gui';
import init from 'three-dat.gui';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import SplitText from '../../lib/com/greensock/gsap-bonus/SplitText';
import * as InertiaPlugin from '../../lib/com/greensock/gsap-bonus/InertiaPlugin';
import { IndexMenu } from '../menu/IndexMenu';
import { WindowManager } from '../utils/WindowManager';
import { IndexContentDisplay } from './../menu/IndexContentDisplay';
init(Dat);

class BrowserModule {
	public element: HTMLElement;
	public loaded: boolean;
	private slidUp: boolean;
	private _indexMenu: IndexMenu;

	constructor() {
		this.element = document.querySelector('.BrowserModule');
		this.loaded = false;

		//@ts-ignore
		gsap.registerPlugin(Draggable, InertiaPlugin, SplitText);

		if (Globals.GUI_ENABLED) {
			this.addGui();
		}

		if (Globals.IS_TOUCH_DEVICE) {
			document.body.classList.add('touch');
		}
	}

	private dataLoaded = data => {
		this.loaded = true;
		this._indexMenu = new IndexMenu(data, document.querySelector('.IndexContentDisplay'));

		this._indexMenu.resize();
		WindowManager.signalResize.add(this._indexMenu.resize);
	};

	private addGui = () => {
		Globals.GUI = new Dat.GUI();
		Globals.GUI.width = 370;

		let guiContainer: HTMLElement = <HTMLElement>Globals.GUI.domElement.parentNode;
		guiContainer.style.zIndex = '100000';

		let guiInner: HTMLElement = guiContainer.querySelector('.dg .main');
		guiInner.style.margin = '0';
	};

	public async load() {
		if (this.loaded) {
			return;
		} else {
			this.init();
		}
	}

	public slideUp(speed = 0.7) {
		this.slidUp = true;
		let elements: Array<HTMLElement> = [this.element];
		//this.stopAnimation();
		return new Promise(resolve => {
			if (this._indexMenu) {
				elements.push(this._indexMenu.element);
			}
			gsap.to(elements, speed, {
				y: -WindowManager.height / 2,
				ease: 'Power2.easeInOut',
				onComplete: resolve
			});
		});
	}

	public slideDown(speed = 0.7) {
		this.slidUp = false;
		let elements: Array<HTMLElement> = [this.element];
		//this.startAnimation();
		return new Promise(resolve => {
			if (this._indexMenu) {
				elements.push(this._indexMenu.element);
			}
			gsap.to(elements, speed, {
				y: 0,
				ease: 'Power2.easeInOut',
				onComplete: () => {
					resolve();
					//this.startAnimation();
				}
			});
		});
	}

	public init = () => {
		fetch('/assets/data/index.json')
			.then(response => response.json())
			.then(this.dataLoaded);
	};

	get indexMenu() {
		return this._indexMenu;
	}
}

export const browserModule = new BrowserModule();

export class Tracking {
	public pageView(url: string) {
		window.gtag('config', window.gtagId, { page_path: url });
	}

	public event(event: string, category: string, label: string) {
		window.gtag('event', event, {
			event_category: category,
			event_label: label
		});
	}
}
export const tracking = new Tracking();

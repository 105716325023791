import { showData } from "../modules/ShowData";
import gsap from "gsap";
import { BASE_PATH, CURRENT_OVERLAY, FIRST_ROUTE } from "../utils/Contants";
//import { router, state } from "../Main";
//import { gallery3d } from "../modules/gallery/Gallery3d";
import {
	Dropdown, DROPDOWN_OPEN,
	DROPDOWN_SELECT,
	DropdownItem
} from "../components/Dropdown";
import { Autocomplete } from "../components/Autocomplete";
import { colorizer, THEME_DARK } from "../modules/Colorizer";
import { browserModule } from "../modules/BrowserModule";
import { SlidePage } from "./SlidePage";
import { WindowManager } from "../utils/WindowManager";
import { Globals } from "../data/Globals";
import { ProjectIndexItem } from "../modules/ProjectIndexItem";
import {IndexMenu} from "../menu/IndexMenu";
import { Point } from '../utils/Point';
import { dynamicSort } from '../utils/Helpers';

export const PROJECT_INDEX_SELECT: string = "project-index-select";

interface programmeYearInterface {
	programmeName: string;
	year: string;
}

class ProjectIndexPage extends SlidePage {
	public element: HTMLElement;
	private categoriesMenu: HTMLElement;
	private categoryLinks: NodeListOf<HTMLElement>;
	private dropdownEls: NodeListOf<HTMLElement>;
	private dropdowns: { [key: string]: Dropdown } = {};
	private Autocomplete: Autocomplete;
	private filterSticky: HTMLElement;
	private filterStickyHeight: number;
	private filterToggleButton: HTMLElement;
	private currentCategoryId: String = "shows";
	private filterBar: HTMLElement;
	private filters: HTMLElement;
	private siteLogo: HTMLElement;

	private showsDefaultValue: string = 'Select Show';
	private unitsDefaultValue: string = 'All';
	private yearsDefaultValue: string = 'All Years';
	private programmesDefaultValue: string = 'All Programmes';
	private hideProgrammeUnits: Array<string> = ['Bio-ID', 'MAHUE'];
	private hideUnitSlugs: Array<string> = ['summershow20-ais-year', 'summershow20-meng'];
	private programmeYears: Array<programmeYearInterface>;
	private showId: string = "";

	constructor(element) {
		super(element);

		this.dropdownEls = document.querySelectorAll(".Dropdown");
		this.filterSticky = element.querySelector(".filter-sticky");
		this.filterToggleButton = element.querySelector(".filter-toggle");
		this.filterBar = element.querySelector(".filter-bar");
		this.filters = element.querySelector(".filter-bar .filters-wrapper");
		this.siteLogo = document.querySelector(".main-logo");

		this.Autocomplete = new Autocomplete(
			document.querySelector(".Autocomplete"),
			this.onAutocompleteUpdate.bind(this)
		);

		this.dropdownEls.forEach(dropdownEL => {
			const id = dropdownEL.getAttribute('id');
			this.dropdowns[id] = new Dropdown(dropdownEL);
			if (dropdownEL.parentElement.classList.contains('disabled')) {
				this.dropdowns[id].disable();
			}
		});
		this.setupDropdowns();

		this.categoriesMenu = this.element.querySelector(".categories-menu");
		this.categoryLinks = this.categoriesMenu.querySelectorAll("a");

		this.loadJSON();
	}

	private setupDropdowns() {
		this.dropdowns["programmes"].on(DROPDOWN_SELECT, e => {
			const showId = this.dropdowns["shows"].selectedKey;
			const programmeId = e.value;

			if (programmeId !== '' && this.hideProgrammeUnits.indexOf(programmeId) < 0) {
				const data = showData.getUnitsFromProgramme(showId, programmeId);
				this.setupUnitFilters(data, showId, programmeId);
			}  else {
				this.resetUnitFilters();
			}

			this.updateResults();
		})
		this.dropdowns["programmes"].on(DROPDOWN_OPEN, this.closeDropdowns.bind(this));

		this.dropdowns["units"].on(DROPDOWN_SELECT, this.updateResults.bind(this));
		this.dropdowns["units"].on(DROPDOWN_OPEN, this.closeDropdowns.bind(this));

		this.dropdowns["years"].on(DROPDOWN_SELECT, this.updateResults.bind(this));
		this.dropdowns["years"].on(DROPDOWN_OPEN, this.closeDropdowns.bind(this));
	}

	private closeDropdowns(dd) {
		for (const dropdown in this.dropdowns) {
			if (dd.value !== this.dropdowns[dropdown]) {
				this.dropdowns[dropdown].hideDropdown();
			}
		}
	}

	public initialize() {
		// Filter toggle
		this.element.addEventListener("scroll", this.onScroll.bind(this));
		WindowManager.signalResize.add(this.onResize.bind(this));
		this.filterToggleButton.addEventListener(
			"click",
			this.toggleFilterBar.bind(this)
		);

		// CATEGORY MENU
		this.categoryLinks.forEach((categoryLink: HTMLAnchorElement) => {
			categoryLink.addEventListener("click", () => {
				this.categoryChange(categoryLink);
				this.dropdowns["filter-categories"].updateSelectedItem(
					categoryLink.innerHTML
				);
			});
		});

		const categoriesDropdown = this.dropdowns["filter-categories"];
		categoriesDropdown.on(DROPDOWN_SELECT, e => {
			const catLink = [...this.categoryLinks].filter(
				el => el.dataset.category == e.value
			)[0];
			this.categoryChange(catLink);
		});

		// STUDENTS AUTO COMPLETE
		const studentNameInput = this.element.querySelector(
			".student-query"
		) as HTMLInputElement;
		studentNameInput.addEventListener("keyup", e => {
			let results = showData.getStudents(this.dropdowns["shows"].selectedKey, studentNameInput.value);
			results = results.sort(dynamicSort('name'));
			this.Autocomplete.populate(results);
		});

		// this.setupProgrammeFilters();
		// this.setupUnitFilters()
		// this.setupYearFilters();

		this.resetProgrammeFilters();
		this.resetUnitFilters();
		this.resetYearFilters();
		this.resetStudents();

		this.Autocomplete.disable();
	}

	private setActiveCategory(category) {
		this.categoryLinks.forEach((categoryLink: HTMLAnchorElement) => {
			const cat = categoryLink.dataset.category;
			if (cat === category) {
				categoryLink.classList.add("active");
				this.categoryChange(categoryLink);
			} else {
				categoryLink.classList.remove("active");
			}
		});
	}

	private categoryChange(categoryLink) {
		if (categoryLink) {
			this.currentCategoryId = categoryLink.dataset.category;
		}

		const cat = categoryLink.dataset.category;
		const activeLink = this.categoriesMenu.querySelector("a.active");
		activeLink.classList.remove("active");
		categoryLink.classList.add("active");
		this.element.querySelectorAll(".filter.active").forEach(filter => {
			filter.classList.remove("active");
		});
		this.element
			.querySelectorAll(".filter[data-category='" + cat + "'], .filter[data-category='all']")
			.forEach(filter => {
				filter.classList.add("active");
			});

		// this.resetProgrammeFilters();
		// this.resetUnitFilters();
		// this.resetYearFilters();

		switch (cat) {
			case "shows":
				//this.updateResults();
				//this.updateResults(showData.getProjectsByProgrammeAndUnit());
				break;

			case "students":
				//this.updateResults();
				//this.updateResults(showData.getProjectsByStudent());
				break;
		}
	}

	private onAutocompleteUpdate(val) {
		const data = showData.getFilteredProjectsByStudent(this.dropdowns["shows"].selectedKey, val);
		this.displayResults( data );
	}

	public loadJSON() {
		fetch('/assets/data/shows.json')
			.then(response => response.json())
			.then(this.setupShowsFilters.bind(this));
	}

	// SHOWS
	private setupShowsFilters(data) {
		let dropdownData;
		const showsDropdown = this.dropdowns["shows"];

		// Programmes dropdown
		dropdownData = [new DropdownItem("", this.showsDefaultValue)];
		data.shows.forEach(show => {
			dropdownData.push(

				new DropdownItem(
					show.id,
					show.title
				)
			);
		});
		showsDropdown.populate(dropdownData);

		showsDropdown.on(DROPDOWN_SELECT, e => {
			const showId = e.value;
			if (showId === '') {
				this.resetPage();
			} else {
				this.Autocomplete.enable();
				this.dropdowns["shows"].element.parentElement.classList.add('loading');
				this.onShowDropdownChange(showId);
			}
		})
		showsDropdown.on(DROPDOWN_OPEN, this.closeDropdowns.bind(this));
	}

	private resetPage() {
		console.log('resset page')
		const resultsEl = this.element.querySelector(".results-container .contents");
		resultsEl.innerHTML = "";

		this.resetUnitFilters();
		this.resetProgrammeFilters()
		this.resetYearFilters();
		this.dropdowns["programmes"].element.parentElement.classList.add('disabled');
		this.dropdowns["programmes"].disable();
		this.dropdowns["years"].element.parentElement.classList.add('disabled');
		this.dropdowns["years"].disable();

		this.Autocomplete.disable();
	}

	private async onShowDropdownChange(showId) {
		await showData.load(showId);
		this.showId = showId;
		this.dropdowns["shows"].element.parentElement.classList.remove('loading');
		const data = showData.getShowFromJson(showId)

		this.setupProgrammeFilters(data['programmes'], showId);
		this.resetUnitFilters();
		this.setupYearFilters(data['years']);

		// EXCEPTION: Toggle filters for PHD
		if (showId === 'phd') {
			this.dropdowns["programmes"].element.parentElement.classList.add('hide');
			this.dropdowns["units"].element.parentElement.classList.add('hide');
		} else {
			this.dropdowns["programmes"].element.parentElement.classList.remove('hide');
			this.dropdowns["units"].element.parentElement.classList.remove('hide');
		}

		this.displayResults( showData.getFilteredProjects(showId) );
	}

	private sortUnits(showId, programmeId, data) {
		const isSummerBSc = showId === 'summer' && programmeId === 'BSc' ? true : false;
		const isAutumnMLA = showId === 'autumn' && programmeId === 'MA / MLA' ? true : false;
		const isBproAD = showId === 'bpro' && programmeId === 'AD' ? true : false;

		if (isSummerBSc || isAutumnMLA || isBproAD) {
			// EXCEPTION: Sorting based on UG1 to UG01 etc
			let number;
			data.forEach(unit => {
				if (unit.code.search('UG') > -1 ||
					unit.code.search('Design Studio') > -1 ||
					unit.code.search('RC') > -1) {
					number = unit.code.match(/\d+/)[0]; // Gets number from string
					unit.sort = number < 10 ? '0' + number : number;
				} else {
					unit.sort = unit.code;
				}
			});
			data = data.sort(dynamicSort('sort'));
		} else {
			data = data.sort(dynamicSort('code'));
		}

		return data;
	}

	// UNITS / WORKS
	private setupUnitFilters(data, showId, programmeId) {
		const unitsDropdown = this.dropdowns["units"];
		const unitElParent = unitsDropdown.element.parentElement;
		let dropdownData;

		dropdownData = [new DropdownItem("", this.unitsDefaultValue)];
		data = this.sortUnits(showId, programmeId, data);

		let unitCounter = 0;
		data.forEach(unit => {
			// EXCEPTION: Due to remapping of 2020 AIS and MEng
			if (showId === 'summer' && programmeId === unit.code) return;
			
			if (unit.code) {
				dropdownData.push(
					new DropdownItem(unit.code, unit.code)
				);
				unitCounter++;
			}
		});
		unitsDropdown.populate(dropdownData);

		if (unitCounter < 2) {
			unitElParent.classList.add('disabled');
			unitsDropdown.disable();
		} else {
			unitElParent.classList.remove('disabled');
			unitsDropdown.enable();
		}
	}

	private resetUnitFilters() {
		const unitsDropdown = this.dropdowns["units"];

		unitsDropdown.element.parentElement.classList.add('disabled');
		let dropdownData = [new DropdownItem("", this.unitsDefaultValue)];
		unitsDropdown.disable();

		unitsDropdown.populate(dropdownData);
	}

	// YEARS
	private setupYearFilters(data) {
		const yearsDropdown = this.dropdowns["years"];
		let dropdownData;

		yearsDropdown.element.parentElement.classList.remove('disabled');
		dropdownData = [new DropdownItem("", this.yearsDefaultValue)];
		yearsDropdown.enable();

		data = data.sort(dynamicSort('year'));

		data.forEach(year => {
			dropdownData.push(
				new DropdownItem(year.year, year.year)
			);
		});
		yearsDropdown.populate(dropdownData);
	}

	private resetYearFilters() {
		const yearsDropdown = this.dropdowns["years"];
		let dropdownData = [new DropdownItem("", this.yearsDefaultValue)];
		yearsDropdown.populate(dropdownData);
	}

	private setupProgrammeFilters( data, showId ) {
		const programmeDropdown = this.dropdowns["programmes"];
		let dropdownData;

		// Programme dropdown
		programmeDropdown.element.parentElement.classList.remove('disabled');
		dropdownData = [new DropdownItem("", this.programmesDefaultValue)];
		programmeDropdown.enable();

		// Sorting for Programmes
		data.forEach(programme => {
			dropdownData.push(
				new DropdownItem(programme.shortTitle, programme.title + " " + programme.qualification)
			);
		});
		programmeDropdown.populate(dropdownData);
	}

	private resetProgrammeFilters() {
		let dropdownData;
		const programmeDropdown = this.dropdowns["programmes"];

		// Programmes dropdown
		dropdownData = [new DropdownItem("", this.programmesDefaultValue)];
		programmeDropdown.populate(dropdownData);
	}

	private resetStudents() {
		this.Autocomplete.populate("");
	}

	public show() {
		super.show();
		//gallery3d.stopAnimation();
	}

	public hide() {
		super.hide();
		//gallery3d.startAnimation();
	}

	public animateIn() {
		colorizer.changeColor(THEME_DARK);
		this.element.scrollTop = 0;

		const activeFilter = this.categoriesMenu.querySelectorAll(".active");
		//const filterId = state.getValue(CURRENT_OVERLAY).filterId;

		// if (filterId) {
		// 	this.setActiveCategory(filterId);
		// } else if (!activeFilter.length) {
		// 	this.setActiveCategory("shows");
		// }

		this.onScroll();
		return super.animateIn();
	}

	public animateInComplete() {
		super.animateInComplete();
		if (browserModule.indexMenu) {
			browserModule.indexMenu.toggle(false);
		}
	}

	public animateOut() {
		if (browserModule.indexMenu) {
			browserModule.indexMenu.toggle(true);
		}

		gsap.to(this.siteLogo, {
			duration: 0.5,
			opacity: 1,
			ease: "Power2.easeOut",
			clearProps: "all"
		});
		colorizer.changeColorBack();
		return super.animateOut();

	}

	// Results updated by any of the dropdowns
	private updateResults() {
		const show = this.dropdowns["shows"].selectedKey;
		const programme = this.dropdowns["programmes"].selectedKey;
		const unit = this.dropdowns["units"].selectedKey;
		const year = this.dropdowns["years"].selectedKey;
		const projectsData = showData.getFilteredProjects(show, programme, unit, year);

		this.displayResults(projectsData, unit);
	}

	private getSelectedYears(showData) {
		const years = [];
		let dataYears = showData.json.shows[0].years;
		if (this.dropdowns["years"].selectedKey !== '') {
			years.push(this.dropdowns["years"].selectedKey)
		} else {
			dataYears = dataYears.sort(dynamicSort('-year'));
			dataYears.forEach((year) => {
				years.push(year.year);
			});
		}

		return years;
	}

	// Inserts a sub header: Programme name + year
	private insertSubheader(meta, resultsWrapper, unitTitle) {
		let found = false
		for(let prog of this.programmeYears){
			if (prog.programmeName ===  meta.programmeTitle && prog.year ===  meta.year) {
				found = true
			}
		}
		if (!found) {
			this.programmeYears.push({'programmeName': meta.programmeTitle, 'year': meta.year});
			const subheadingEl = document.createElement("div");
			const qual = meta.programmeQualification
				? " " + meta.programmeQualification
				: "";
			let programmeName;
			if (unitTitle !== "") {
				programmeName = `${meta.programmeTitle + qual}, ${unitTitle}`;
			} else {
				programmeName = meta.programmeTitle + qual;
			}
			subheadingEl.classList.add('type-unit-sub');
			subheadingEl.innerHTML = `${programmeName}, ${meta.year}`;
			resultsWrapper.append(subheadingEl);
		}
	}

	// Outputs the results to the DOM
	private displayResults(projectsData: Array<any>, unitTitle: string = "") {
		const resultsEl = this.element.querySelector(
			".results-container .contents"
		);
		this.programmeYears = [];
		resultsEl.innerHTML = "";

		const programmes = showData.getProgrammesFromProjectsData(projectsData, this.showId);
		const years = this.getSelectedYears(showData);

		// Loop programmes
		programmes.forEach(programme => {
			const headingEl = document.createElement("div");
			const wrapperEl = document.createElement("div");
			const qual = programme.qualification
				? " " + programme.qualification
				: "";

			// Main Title
			headingEl.dataset.title = programme.title;
			headingEl.classList.add('type-title--med');
			if (unitTitle !== "") {
				headingEl.innerHTML = `${programme.title +
				qual}, ${unitTitle}`;
			} else {
				headingEl.innerHTML = programme.title + qual;
			}

			// DOM
			wrapperEl.classList.add('wrapper');
			resultsEl.append(headingEl);
			resultsEl.append(wrapperEl);

			// Loop years
			years.forEach(year => {
				let projects = showData.getProjectsByProgramme(projectsData, programme.title, year);
				projects = projects.sort(dynamicSort('unitCode'));
				projects.forEach(project => {
					const projectIndexItem = new ProjectIndexItem(
						project,
						this.currentCategoryId
					);
					const meta = projectIndexItem.data.meta;
					let found = 0;

					// EXCEPTION for AIS MEng
					this.hideUnitSlugs.forEach(slug => {
						if (meta.unitSlug.indexOf(slug) > -1 && unitTitle === '') {
							found = 1;

							// Rewrite slug for proper url redirect
							if (meta.unitSlug.indexOf('meng')) {
								projectIndexItem.data.meta.unitSlug = 'meng';
							} else if (meta.unitSlug.indexOf('ais')) {
								projectIndexItem.data.meta.unitSlug = 'ais';
							}
						}
					});
					if (found) return;

					// Check if a Programme / Year subtitle can be inserted
					this.insertSubheader(meta, wrapperEl, unitTitle);

					// Insert Project
					wrapperEl.append(projectIndexItem.element);
				});
			});
		});
	}

	// Mobile Filter Bar: Moving of Filters element into or outside of the quick bar
	private toggleSticky(sticky: boolean) {
		const holder = this.filterSticky.querySelector(".holder");

		if (sticky) {
			this.filterBar.style.height = `${
				this.filterBar.getBoundingClientRect().height
				}px`;
			holder.appendChild(this.filters);
			this.filterToggleButton.classList.add("visible");
		} else {
			this.filterBar.style.height = "auto";
			this.filterBar.appendChild(this.filters);
			this.filterToggleButton.classList.remove("visible");
		}
	}

	// Mobile Filter Bar: Sliding down the background
	private toggleBackgroundBar(sticky: boolean) {
		const duration = 0.3;
		const ease = "Power2.easeOut";

		if (sticky) {
			this.filterSticky.classList.add("visible");

			gsap.to(this.siteLogo, {
				duration,
				opacity: 0,
				ease,
				onComplete: () => {
					this.siteLogo.style.pointerEvents = "none";
				}
			});
			gsap.to(this.filterSticky, { duration, y: 0, ease });
		} else {
			this.filterSticky.classList.remove("visible");
			gsap.to(this.siteLogo, { duration, opacity: 1, ease, clearProps: "all" });
			gsap.to(this.filterSticky, { duration, y: "-100%", ease });
		}
	}

	// Mobile Filter Bar: Opening and closing the Filters
	private toggleFilterBar() {
		const filtersHeight = this.filters.getBoundingClientRect().height;

		if (this.filterToggleButton.classList.contains("active")) {
			this.filterToggleButton.classList.remove("active");
			this.filterSticky.style.overflow = "hidden";

			gsap.to(this.filterSticky, {
				duration: 0.5,
				height: this.filterStickyHeight,
				ease: "Power2.easeOut",
				onComplete: () => {}
			});
		} else {
			this.filterStickyHeight = this.filterSticky.getBoundingClientRect().height;
			this.filterToggleButton.classList.add("active");

			gsap.to(this.filterSticky, {
				duration: 0.5,
				height: filtersHeight + this.filterStickyHeight,
				ease: "Power2.easeOut",
				onComplete: () => {
					this.filterSticky.style.height = "auto";
					this.filterSticky.style.overflow = "visible";
				}
			});
		}
	}

	public onResize() {
		if (WindowManager.width > Globals.BP_MD) {
			this.onScroll();
		}
	}

	public onScroll() {
		const heightForBackgroundToShow = 80;
		if (
			this.element.scrollTop > WindowManager.height &&
			WindowManager.width < Globals.BP_MD
		) {
			this.toggleSticky(true);
		} else {
			this.toggleSticky(false);
		}

		if (
			this.element.scrollTop > heightForBackgroundToShow &&
			WindowManager.width < Globals.BP_MD
		) {
			this.toggleBackgroundBar(true);
		} else {
			this.toggleBackgroundBar(false);
		}
	}
}

export const projectIndexPage = new ProjectIndexPage(
	document.querySelector(".ProjectIndexPage")
);

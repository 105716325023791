import { gsap } from 'gsap';
import * as THREE from 'three';
import { IndexBlock } from './IndexBlock';
import { Globals } from '../data/Globals';
import { clamp, degreesToRadians } from '../../lib/com/hellomonday/utils/MathUtils';
import { MouseInputData } from '../input/MouseInput';
import * as Draggable from 'gsap/Draggable';
import { WheelInput } from '../input/WheelInput';
import { WindowManager } from '../utils/WindowManager';
import { Howl, Howler } from 'howler';
import { logo } from '../components/Logo';
import { tracking } from '../utils/Tracking';

export interface GradientColor {
	hex: string;
	step: number;
}

export interface IndexBlockData {
	title: string;
	date: string;
	gradientColor1: GradientColor;
	gradientColor2: GradientColor;
	gradientColor3: GradientColor;
	gradientColor4: GradientColor;
	glowIntensity: number;
	promoted: boolean;
	link: string;
}

const deltaThreshold: number = 100;

export class IndexMenu {
	public element: HTMLElement;

	private _blockContainer: THREE.Group = new THREE.Group();
	private _blocks: Array<IndexBlock> = [];
	private _totalBlocks: number;

	private _bgPlane: THREE.Mesh;
	private _highlightPointlight: THREE.PointLight;
	private _spotlight: THREE.SpotLight;

	private _state: boolean = false;
	private _position = { value: 0 };
	private _prevID: number = 0;

	private _blockMedian: number;
	private _highlightHelper;

	private _params = { radius: 10, blockDistance: 7, centerPercent: 1 };

	private _wheelInput: WheelInput;
	private _targetMousePosition: THREE.Vector2 = new THREE.Vector2(0, 0);
	private _cursor: HTMLDivElement;
	private _cursorCross;
	private _cursorCircle;
	private _cursorExternal;
	private _raycaster: THREE.Raycaster = new THREE.Raycaster();
	private _blockMeshes: Array<THREE.Mesh> = [];

	private _delta: number = 0;
	private _totalDelta: number;
	private _id: number = 9999;

	private _cursorActive: boolean = false;
	private _introComplete: boolean = false;
	private _cursorHidden: boolean = true;
	private _mouseLeft: boolean = false;

	private _intro: HTMLDivElement;
	private _exploreButton: HTMLDivElement;
	private _indexMenuButton: HTMLAnchorElement;

	private _leftKeyDown: boolean = false;
	private _leftKeyInterval;

	private _rightKeyDown: boolean = false;
	private _rightKeyInterval;

	private _initial: boolean = true;

	private _draggable;

	private _boundsWidth: number = WindowManager.width >= 640 ? Math.round(WindowManager.width * 0.25) : Math.round(WindowManager.width * 0.5);

	private _swipeAudio: Howl;
	private _highlightAudio: Howl;

	private _allowHover: boolean = false;

	private _mobileNav;
	private _mobileNavLeft;
	private _mobileNavRight;

	private _blinkInterval;

	private _active: boolean = true;

	constructor(data, content: HTMLDivElement) {
		this.element = content;
		this._cursor = document.body.querySelector('.cursor');

		if (Globals.IS_TOUCH_DEVICE) {
			this._cursor.style.display = 'none';
		}

		this._swipeAudio = new Howl({
			src: ['/assets/audio/menu/tick.mp3'],
			autoplay: false,
			volume: 0.9
		});

		this._highlightAudio = new Howl({
			src: ['/assets/audio/menu/tick2.mp3'],
			autoplay: false,
			volume: 0.3
		});

		this._cursorCross = this._cursor.querySelector('.cross');
		this._cursorCircle = this._cursor.querySelector('.circle');
		this._cursorExternal = this._cursor.querySelector('.external');
		this._intro = document.body.querySelector('.intro');
		this._exploreButton = document.body.querySelector('.buttonExplore');
		gsap.set(this._cursor, { scale: 0.6, rotation: 45 });

		const geometry = new THREE.PlaneGeometry(200, 200);
		const material = new THREE.MeshPhongMaterial({ color: 0x000000, side: THREE.DoubleSide });
		this._bgPlane = new THREE.Mesh(geometry, material);
		this._bgPlane.castShadow = false;
		this._bgPlane.rotation.x = degreesToRadians(90);

		if (Globals.GUI_ENABLED) {
			// Globals.GUI.addMesh('Floor', this._bgPlane);
			Globals.GUI.addVector('BlockContainer', this._blockContainer.position);
			Globals.GUI.open();
		}

		Globals.WORLD.scene.add(this._bgPlane);

		let l = data.entries.length;

		let id = 0;

		for (let i = 0; i < l; i++) {
			let block = new IndexBlock(data.entries[i], id, this);
			this._blocks.push(block);
			// block.container.position.x = 0.5 * i;
			block.container.position.y = -4.8;
			this._blockContainer.add(block.container);

			this._blockMeshes.push(block.block);

			id++;
		}

		this._totalBlocks = l;

		this._totalDelta = deltaThreshold * this._totalBlocks;

		this._blockMedian = Math.ceil(this._blocks.length / 2) + (this._blocks.length % 2 === 0 ? 0 : -1);

		Globals.WORLD.renderSignal.add(this.updateBlockPositions);

		Globals.WORLD.scene.add(this._blockContainer);

		const pointLight = new THREE.PointLight(0xffffff, 1, 100);
		pointLight.position.set(10, 10, 10);
		pointLight.castShadow = true;
		pointLight.shadow.mapSize.width = 2048;
		pointLight.shadow.mapSize.height = 2048;
		pointLight.shadow.camera.near = 0.5;
		pointLight.shadow.camera.far = 500;

		Globals.WORLD.scene.add(pointLight);

		const pointLight2 = new THREE.PointLight(0xffffff, 1, 100);
		pointLight2.position.set(-10, 10, 10);
		pointLight2.castShadow = false;
		Globals.WORLD.scene.add(pointLight2);

		this._highlightPointlight = new THREE.SpotLight(0xff0000, 5);
		this._highlightPointlight.position.set(2.9, 2.2, 0);
		this._highlightPointlight.angle = degreesToRadians(23);
		this._highlightPointlight.penumbra = 0.2;

		this._highlightHelper = new THREE.SpotLightHelper(this._highlightPointlight);

		this._mobileNav = document.body.querySelector('.mobileNav');
		this._mobileNavLeft = this._mobileNav.querySelector('.arrowLeft');
		this._mobileNavRight = this._mobileNav.querySelector('.arrowRight');

		if (!Globals.IS_TOUCH_DEVICE) {
			Globals.MOUSE_INPUT.add(this.onMouseMove);
			window.addEventListener('mouseout', this.onMouseLeave);
			window.addEventListener('mouseover', this.onMouseEnter);
			this._mobileNavLeft.addEventListener('click', this.goLeft);
			this._mobileNavRight.addEventListener('click', this.goRight);
		} else {
			this._mobileNavLeft.addEventListener('touchstart', this.goLeft);
			this._mobileNavRight.addEventListener('touchstart', this.goRight);
		}

		gsap.set(this._mobileNavRight, { rotation: 90, transformOrigin: '50% 50%' });
		gsap.set(this._mobileNav, { y: 55 });

		this._indexMenuButton = document.body.querySelector('.index-button span');
		this._indexMenuButton.addEventListener('mouseout', () => {
			this._cursorHidden = true;
			this._cursor.style.display = 'block';
			this._cursor.style.backgroundColor = 'blue';
		});
		this._indexMenuButton.addEventListener('mouseover', () => {
			this._cursorHidden = false;
			this._cursor.style.display = 'none';
			this._cursor.style.backgroundColor = 'red';
		});

		this.start();
	}

	private onKeyDown = e => {
		if (!this._active) {
			return;
		}

		if (e.key === 'ArrowLeft' && !this._leftKeyDown) {
			this._leftKeyDown = true;

			if (this._leftKeyInterval) {
				clearInterval(this._leftKeyInterval);
			}
			this._leftKeyInterval = setInterval(this.goLeft, 500);
			this.goLeft();
		}

		if (e.key === 'ArrowRight' && !this._rightKeyDown) {
			this._rightKeyDown = true;

			if (this._rightKeyInterval) {
				clearInterval(this._rightKeyInterval);
			}
			this._rightKeyInterval = setInterval(this.goRight, 500);
			this.goRight();
		}
	};

	private onKeyUp = e => {
		if (!this._active) {
			return;
		}

		if (e.key === 'ArrowLeft' && this._leftKeyDown) {
			if (this._leftKeyInterval) {
				clearInterval(this._leftKeyInterval);
			}
			this._leftKeyDown = false;
		}

		if (e.key === 'ArrowRight' && this._rightKeyDown) {
			if (this._rightKeyInterval) {
				clearInterval(this._rightKeyInterval);
			}
			this._rightKeyDown = false;
		}
	};

	private goLeft = () => {
		if (!this._active) {
			return;
		}

		let id = this._id + 1;

		if (id < this._totalBlocks) {
			this.updateBlocks(this._totalBlocks * (id / this._totalBlocks), id);
			this.dragEnd();
		}

		this.updateMobileNavArrowVisibility(id);
	};

	private goRight = () => {
		if (!this._active) {
			return;
		}

		let id = this._id - 1;

		if (id > -1) {
			this.updateBlocks(this._totalBlocks * (id / this._totalBlocks), id);
			this.dragEnd();
		}

		this.updateMobileNavArrowVisibility(id);
	};

	private updateMobileNavArrowVisibility = (id: number) => {
		gsap.to(this._mobileNavLeft, {
			opacity: id < this._totalBlocks - 1 ? 1 : 0,
			duration: 0.2,
			overwrite: true,
			rotation: id < this._totalBlocks - 1 ? 0 : -90,
			transformOrigin: '50% 50%',
			ease: 'power2.inOut'
		});
		gsap.to(this._mobileNavRight, {
			opacity: id > 0 ? 1 : 0,
			duration: 0.2,
			overwrite: true,
			rotation: id > 0 ? 0 : 90,
			transformOrigin: '50% 50%',
			ease: 'power2.inOut'
		});
		// this._mobileNavLeft.style.display = id < this._totalBlocks - 1 ? 'block' : 'none';
		// this._mobileNavRight.style.display = id > 0 ? 'block' : 'none';
	};

	private onMouseLeave = () => {
		if (!this._active) {
			return;
		}

		if (!this._mouseLeft) {
			this._mouseLeft = true;
			gsap.to(this._cursor, { opacity: 0, duration: 0.3 });
		}
	};

	private onMouseEnter = () => {
		if (!this._active) {
			return;
		}

		if (this._mouseLeft) {
			this._mouseLeft = false;
			gsap.to(this._cursor, { opacity: 1, duration: 0.3 });
			this.checkCursor();
		}
	};

	private start = () => {
		let l = this._blocks.length;

		for (let i = 0; i < l; i++) {
			gsap.to(this._blocks[i].container.position, { y: 0, duration: 0.75, delay: 0.2 + i * 0.03, ease: 'back.out' });
		}

		gsap.delayedCall(0.75 + 0.2 + l * 0.03 - 0.03, this.animateIn);
	};

	private animateIn = () => {
		this._state = !this._state;
		let duration = 2.5;
		let delay = 0.05;
		let l = this._blocks.length;

		gsap.to(this._params, { radius: 28, blockDistance: 2, centerPercent: 1, duration: duration });
		gsap.delayedCall(duration + l * delay, this.showExploreButton);

		for (let i = 0; i < l; i++) {
			gsap.to(this._blocks[i], {
				position: this._state ? -180 - 4 : 0,
				duration: duration,
				delay: this._state ? this._blocks[i].id * delay : (l - 1 - this._blocks[i].id) * delay,
				ease: 'Power2.inOut',
				overwrite: true
			});

			// gsap.killTweensOf(this.resetRotation);

			// gsap.to(this._blocks[i].container.rotation, {
			// 	z: this._state ? degreesToRadians(10) : degreesToRadians(-10),
			// 	duration: duration * 0.5,
			// 	delay: this._state ? this._blocks[i].id * delay : ((l - 1) - this._blocks[i].id) * delay,
			// 	onComplete: this.resetRotation,
			// 	onCompleteParams: [this._blocks[i].container, duration * 0.5],
			// 	ease: 'Power2.inOut',
			// 	overwrite: true
			// });
		}
	};

	private showExploreButton = () => {
		if (Globals.DEBUG) {
			this.addInputs();
		} else {
			this._exploreButton.addEventListener('click', this.addInputs);
			gsap.to(this._exploreButton, { opacity: 1 });
			logo.minimize();

			this.triggerBlinkSequence();
			this._blinkInterval = setInterval(this.triggerBlinkSequence, 3000);
		}
	};

	private triggerBlinkSequence = () => {
		let l = this._blocks.length;

		for (let i = 0; i < l; i++) {
			gsap.delayedCall(0.2 * i, this._blocks[i].blink);
		}
	};

	private updateLight = () => {
		this._highlightPointlight.target.updateMatrixWorld();
		this._highlightHelper.update();
	};

	private addInputs = () => {
		if (this._blinkInterval) {
			clearInterval(this._blinkInterval);

			let l = this._blocks.length;

			for (let i = 0; i < l; i++) {
				gsap.killTweensOf(this._blocks[i].blink);
			}
		}

		if (!Globals.IS_TOUCH_DEVICE) {
			document.addEventListener('keydown', this.onKeyDown);
			document.addEventListener('keyup', this.onKeyUp);
		}

		this._exploreButton.removeEventListener('click', this.addInputs);
		gsap.to(this._intro, { opacity: 0, y: -100, duration: 0.3 });

		gsap.to(this._params, {
			radius: 10,
			// blockDistance: 11,
			delay: 0.3,
			duration: 0.5,
			ease: 'power2.inOut',
			onComplete: this.removeIntro
		});
	};

	private removeIntro = () => {
		this._intro.parentNode.removeChild(this._intro);

		console.log('remove intro');

		this._introComplete = true;

		// Activate tabs in blocks
		this._blocks.forEach(block => block.setActiveTab());

		if (this._blocks[0].link === '') {
			gsap.to(this._cursorCross, { duration: 0.2, opacity: 0 });
			gsap.to(this._cursorCircle, { fillOpacity: 0.1 });
			gsap.to(this._cursor, { duration: 0.2, scale: 0.6, rotation: 0 });
			gsap.to(this._cursorExternal, { duration: 0.2, opacity: 0 });
		} else {
			gsap.to(this._cursorCross, { duration: 0.2, opacity: 0 });
			gsap.to(this._cursorCircle, { fillOpacity: 1 });
			gsap.to(this._cursor, { duration: 0.2, scale: 1, rotation: 0 });
			gsap.to(this._cursorExternal, { duration: 0.2, opacity: 1 });
		}

		this._cursorActive = true;

		// this.updateBlocks(0, 0);

		gsap.to(this._params, {
			// delay: 0.3,
			blockDistance: 11,
			duration: 0.5,
			ease: 'power2.out',
			// onComplete: this.updateBlocks,
			onComplete: () => {
				this._allowHover = true;
			}
			// onCompleteParams: [0, 0]
		});

		this.updateBlocks(0, 0, 0.5); //, 0.3);

		gsap.to(this._mobileNav, { y: 0, opacity: 1, duration: 0.3 });

		//this.updateBlocks((deltaThreshold / this._totalDelta) , 1);

		if (!Globals.DISABLE_CONTROLS) {
			if (Globals.IS_TOUCH_DEVICE) {
				Globals.WORLD.renderer.domElement.addEventListener('touchstart', this.onTouchStart);
				Globals.WORLD.renderer.domElement.addEventListener('click', this.onTouchClick);
			} else {
				window.addEventListener('click', this.onClick);
			}

			this._draggable = Draggable.Draggable.create(document.createElement('div'), {
				type: 'x',
				lockAxis: true,
				minimumMovement: 3,
				onDragStart: this.dragStart,
				onDrag: this.draggableUpdate,
				onDragEnd: this.dragEnd,
				trigger: document.body,
				//@ts-ignore
				bounds: { minX: 0, maxX: this._boundsWidth },
				throwProps: true,
				cursor: 'none'
			});

			// if (!Globals.DEBUG) {
			if (!Globals.IS_TOUCH_DEVICE) {
				this._wheelInput = new WheelInput();
				this._wheelInput.add(this.onWheel);
			}
			// }
			this.checkCursor();
		}
	};

	private dragStart = () => {
		gsap.killTweensOf(this.dragEnd);
		gsap.killTweensOf(this._blocks);
	};

	private dragEnd = () => {
		this.updateBlocks(this._totalBlocks * (this._id / this._totalBlocks), this._id);

		this._delta = clamp(this._id * deltaThreshold, 0, this._totalDelta);

		let deltaPercent = this._delta / this._totalDelta;

		if (!Globals.DISABLE_CONTROLS) {
			gsap.set(this._draggable[0].target, { x: this._boundsWidth * deltaPercent });
			this._draggable[0].update();
		}
	};

	private draggableUpdate = () => {
		let draggedPercent = this._draggable[0].x / this._boundsWidth;
		let position = (this._totalBlocks - 1) * draggedPercent;
		let id = clamp(Math.round(position), 0, this._totalBlocks - 1);

		this._delta = clamp(this._totalDelta * draggedPercent, 0, this._totalDelta);

		this.updateBlocks(position, id);
	};

	private onWheel = e => {
		if (!this._active) {
			return;
		}

		this._delta = clamp(this._delta + e.deltaY, 0, this._totalDelta);

		let deltaPercent = this._delta / this._totalDelta;
		let position = (this._totalBlocks - 1) * deltaPercent;
		let id = clamp(Math.round(position), 0, this._totalBlocks - 1);

		gsap.set(this._draggable[0].target, { x: this._boundsWidth * deltaPercent });
		this._draggable[0].update();

		this.updateBlocks(position, id);

		// Snap when done scrolling
		gsap.killTweensOf(this.dragEnd);
		gsap.delayedCall(0.5, this.dragEnd);
	};

	private updateBlocks = (position: number, id: number, duration: number = 0.5, delay: number = 0) => {
		let promotion = this._blocks[0].promoted && (id === 0 || this._prevID === 0);

		//let delay = 0; //promotion ? 0 : 0.03;
		// let duration = 0.5; //promotion ? 1 : 0.3;
		let l = this._blocks.length;

		// gsap.to(this._params, {radius: id === 0 ? 23 : 20, blockDistance: id === 0 ? 3 : 1.6, duration: duration, delay: delay, ease: 'Power2.out'});

		let speed = 1;

		// gsap.to(this._params, {
		// 	radius: 10, //this._blocks[0].promoted && id === 0 ? 16.5 : 10,
		// 	blockDistance: 11, //this._blocks[0].promoted && id === 0 ? 14 : 11,
		// 	duration: this._initial ? speed : duration,
		// 	delay: delay,
		// 	ease: 'power2.out',
		// 	overwrite: true
		// });

		let direction = id < this._prevID ? 'left' : 'right';
		this._prevID = id;

		// let totalDelay = 0;

		for (let i = 0; i < l; i++) {
			//totalDelay = direction === 'right' ? this._blocks[i].id * delay : ((l - 1) - this._blocks[i].id) * delay;

			if (id !== this._id) {
				// this._blocks[i].toggleHighlight(i === id, promotion || this._initial ? speed : duration * 0.5);// + totalDelay);
				//this._blocks[i].toggleHighlight(i === id, promotion || this._initial ? speed * 0.5 : duration * 0.5);// + totalDelay);
				this._blocks[i].toggleHighlight(i === id, duration); // * 0.5); // + totalDelay);
			}

			gsap.to(this._blocks[i], {
				position: -180 - position * this._params.blockDistance,
				duration: duration, //this._initial ? speed : duration, //duration,
				delay: delay,
				// delay: totalDelay,
				ease: 'power2.out',
				overwrite: true,
				onComplete: this.checkCursor
			});
		}

		if (id !== this._id) {
			this._id = id;

			this.updateMobileNavArrowVisibility(id);

			//this._swipeAudio.stop();
			this._swipeAudio.play();
		}

		if (this._initial) {
			this._initial = false;
		}
	};

	private onMouseMove = (data: MouseInputData) => {
		if (!this._active) {
			return;
		}

		if (!this._introComplete) {
			if (data.target.classList.contains('buttonExplore')) {
				gsap.to(this._cursorCross, { duration: 0.2, opacity: 1 });
				gsap.to(this._cursorCircle, { fillOpacity: 1 });
				gsap.to(this._cursor, { duration: 0.2, scale: 1, rotation: 0 });
			} else {
				gsap.to(this._cursorCross, { duration: 0.2, opacity: 0 });
				gsap.to(this._cursorCircle, { fillOpacity: 0.1 });
				gsap.to(this._cursor, { duration: 0.2, scale: 0.6, rotation: 45 });
			}
		}

		gsap.set(this._cursor, { x: data.x, y: data.y });

		if (this._cursorHidden) {
			this._cursorHidden = false;
			gsap.to(this._cursor, { opacity: 1 });
		}

		this._targetMousePosition.x = (data.x / WindowManager.width) * 2 - 1;
		this._targetMousePosition.y = -(data.y / WindowManager.height) * 2 + 1;

		if (this._introComplete) {
			this.checkCursor();
		}
	};

	private checkCursor = (click: boolean = false) => {
		if (!this._active) {
			return;
		}

		if (!this._introComplete) {
			return;
		}

		this._raycaster.setFromCamera(this._targetMousePosition, Globals.WORLD.camera);
		let intersects = this._raycaster.intersectObjects(this._blockMeshes);

		let l = intersects.length;
		let closestBlock = 9999;
		let id = 9999;
		let active = l > 0;

		for (let i = 0; i < l; i++) {
			if (intersects[i].distance < closestBlock) {
				closestBlock = intersects[i].distance;
				id = parseInt(intersects[i].object.name.replace('Block_', ''));
			}
		}

		// Hover effect
		if (!Globals.IS_TOUCH_DEVICE && this._allowHover) {
			for (let i = 0; i < this._blocks.length; i++) {
				if (id !== this._id && id === i) {
					this._blocks[i].toggleHover(true);
				} else if (id !== this._id && id !== i) {
					this._blocks[i].toggleHover(false);
				}
			}
		}

		// If link is empty cursor to default
		if (id === this._id && this._blocks[id].link === '') {
			this._cursorActive = true;
		}

		if (this._cursorActive !== active) {
			gsap.to(this._cursorCross, { duration: 0.2, opacity: !this._cursorActive && id !== this._id ? 1 : 0 });
			gsap.to(this._cursorCircle, { duration: 0.2, fillOpacity: !this._cursorActive ? 1 : 0.1 });
			gsap.to(this._cursorExternal, { duration: 0.2, opacity: !this._cursorActive && id === this._id ? 1 : 0 });
			gsap.to(this._cursor, { duration: 0.2, scale: !this._cursorActive ? 1 : 0.6, rotation: !this._cursorActive ? 0 : 45 });
		}

		this._cursorActive = active;

		// console.log('id: ' + id);
		// console.log('this._id: ' + this._id);
		// console.log('this._cursorActive: ' + this._cursorActive);
		// console.log('click: ' + click);

		let returnData = { open: false, link: null };

		// Find out which block is clicked
		if (click && this._cursorActive) {
			if (id === this._id && this._blocks[id].link !== '') {
				returnData.open = true;
				returnData.link = this._blocks[id].link;
				tracking.event('click', 'browser', this._blocks[id].link);
				window.open(this._blocks[id].link, 'blank');
			} else {
				// Jump clicked block if its not active
				this.updateBlocks(this._totalBlocks * (id / this._totalBlocks), id);
				this.dragEnd();
			}
		}

		return returnData;
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Blocks');

		//@ts-ignore
		top.add(this._params, 'radius', 0, 50)
			.onChange(this.updateBlockPositions)
			.step(0.5)
			.listen();

		//@ts-ignore
		top.add(this._params, 'blockDistance', 1, 50)
			.onChange(this.updateBlockPositions)
			.step(0.5)
			.listen();

		top.open();

		// Globals.GUI.addLight('Pointlight', pointLight);
	};

	private onTouchStart = e => {
		if (!this._active) {
			return;
		}

		this._targetMousePosition.x = (e.touches[0].clientX / WindowManager.width) * 2 - 1;
		this._targetMousePosition.y = -(e.touches[0].clientY / WindowManager.height) * 2 + 1;

		// let cursorCheck = this.checkCursor(true);
		// if (cursorCheck.open) {
		// 	console.log('cursorCheck.link: ' + cursorCheck.link);
		// 	window.open(cursorCheck.link, 'blank');
		// }
	};

	private onTouchClick = e => {
		if (!this._active) {
			return;
		}

		let cursorCheck = this.checkCursor(true);
		if (cursorCheck.open) {
			console.log('cursorCheck.link: ' + cursorCheck.link);
			window.open(cursorCheck.link, 'blank');
		}
	};

	private onClick = e => {
		if (!this._active) {
			return;
		}

		this.checkCursor(true);
	};

	private updateBlockPositions = () => {
		this.updateLight();

		// Move the block container away from the camera based on the block circle radius
		this._bgPlane.position.z = -(this._params.radius * 2 + 1.5 + 5);
		this._blockContainer.position.z = -(this._params.radius * this._params.centerPercent);

		let l = this._blocks.length;

		let offset = 0;

		for (let i = 0; i < l; i++) {
			let angle = i * this._params.blockDistance - 90 + this._blocks[i].position;

			// NOTE: the blocks move counter clockwise, which means that the first block is the one on the right

			// Push the next block backward
			if (this._blocks[i - 1]) {
				offset += this._blocks[i - 1].offset * 0.75;
				angle += offset;
			}

			// Push the previous block forward
			// if (i !== 0) {
			// 	offset += this._blocks[i].offset;
			// 	angle += offset;
			// }

			// Calculate circular position/rotation
			let x = this._params.radius * Math.cos(degreesToRadians(angle));
			let z = this._params.radius * Math.sin(degreesToRadians(angle));

			this._blocks[i].container.position.x = x;
			this._blocks[i].container.position.z = z;
			this._blocks[i].container.rotation.y = degreesToRadians(angle + 90) * -1;

			// this._blocks[i].updateHelper();
		}
	};

	public resize = () => {
		this._boundsWidth = WindowManager.width >= 640 ? Math.round(WindowManager.width * 0.25) : Math.round(WindowManager.width * 0.5);

		//@ts-ignore
		if (this._draggable && this._draggable[0]) {
			this._draggable[0].applyBounds({ minX: -this._boundsWidth, maxX: this._boundsWidth });
		}

		this._blockContainer.position.x = WindowManager.width >= 768 ? 0 : -2.8;
	};

	public toggle = (state: boolean) => {
		if (state !== this._active) {
			this._active = state;

			if (state) {
				Globals.WORLD.start();
				if (!Globals.IS_TOUCH_DEVICE && this._wheelInput) {
					this._wheelInput.enable();
				}
			} else {
				Globals.WORLD.stop();
				if (!Globals.IS_TOUCH_DEVICE && this._wheelInput) {
					this._wheelInput.disable();
				}
			}
		}
	};

	get active() {
		return this._active;
	}

	get highlightPointLight() {
		return this._highlightPointlight;
	}

	get hightlightPointLightHelper() {
		return this._highlightHelper;
	}

	get highlightAudio() {
		return this._highlightAudio;
	}
}

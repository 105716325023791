//@ts-ignore
export const BASE_PATH = window.BASE_PATH;
export const FIRST_ROUTE = 'first_route';

export const OFF_BLACK_HEX = '#131214';
export const OFF_BLACK_DEC = 0x131214;

export const LIGHT = 'light';
export const DARK = 'dark';

export const OVERLAY_LAYER = '.overlay-layer';

export const CURRENT_3D_VIEW = 'current_3d_view';
export const LAST_3D_PATH = 'last_3d_Path';
export const BROWSER_MODULE = 'browser_module';
export const CURRENT_OVERLAY = 'current_overlay';
export const LAST_OVERLAY = 'last_overlay';

export const PROJECT_INDEX = 'project_index';
export const PROJECT_PAGE = 'project_page';

export const LOAD_PROGRESS = 'load_progress';
export const LOAD_COMPLETE = 'load_complete';

export const RENDERER = 'renderer';

import { BASE_PATH } from '../utils/Contants';
import { state } from '../Main';
import { PROJECT_INDEX_SELECT } from '../templates/ProjectIndexPage';
import { tracking } from '../utils/Tracking';

export class ProjectIndexItem {
	public element: HTMLElement;
	public data: any;

	constructor(projectData, activeCategory) {
		const isBpro = projectData.meta.baseUrl.indexOf('bpro') > -1 ? true : false;
		const isPhD = projectData.meta.baseUrl.indexOf('phd') > -1 ? true : false;
		const isFifteen = projectData.meta.baseUrl.indexOf('fifteen') > -1 ? true : false;
		const isBioID = projectData.meta.programme === 'Bio-ID' ? true : false; // BPro
		const isMAHUE = projectData.meta.programme === 'MAHUE' ? true : false; // Autumn Show
		const isMEng = projectData.meta.programme === 'MEng' ? true : false; // SS
		const isAIS = projectData.meta.programme === 'AIS' ? true : false; // SS
		const isAC = projectData.meta.programme === 'AC' ? true : false; // BPro
		const isBproAC = isBpro && isAC ? true : false; // BPro AC
		const isAutumnMLA = projectData.meta.programme === 'MA / MLA' ? true : false; // Autumn

		this.data = projectData;

		//award tag
		let isAward = '';
		projectData.students.forEach(student => {
			if (parseInt(student.awards) > 0) {
				isAward = 'show-award';
			}
		});

		if (parseInt(projectData.awards) > 0) {
			isAward = 'show-award';
		}

		//title
		const div = document.createElement('div');
		let year = projectData.students.length > 0 ? projectData.students[0].year : ''; //.split(', ');
		//year = year.length > 1 ? year[1] : '';
		let subtitle = projectData.meta.programmeTitle + (projectData.meta.programmeQualification ? ' ' + projectData.meta.programmeQualification : '');

		const regNumbersOnly = new RegExp('^[0-9]+$');
		const unitCode = projectData.meta.unitCode;
		if (isBioID) {
			if (year !== '' && regNumbersOnly.test(year)) {
				subtitle += ', Year ' + year;
			} else if (year !== '' && !regNumbersOnly.test(year) && projectData.hideYearInfo !== '1') {
				subtitle += ', ' + year;
			}
			if (projectData.groupName !== '') {
				subtitle += ', ' + projectData.groupName;
			}
		} else {
			if (unitCode && !projectData.groupValue && !isFifteen && !isPhD && !isMAHUE && !isAIS && !isBproAC && subtitle.search(unitCode) < 0) {
				subtitle += ', ' + unitCode;
			}

			// Add group value
			if (projectData.groupValue) {
				subtitle += ', ' + projectData.groupValue;
			}

			// Add year
			let yearSubtitle = '';
			if (year && unitCode !== year && projectData.hideYearInfo !== '1') {
				let yearPrefix = isAutumnMLA && projectData.meta.year < 2021 ? '' : 'Year ';

				if (!regNumbersOnly.test(year)) {
					yearPrefix = '';
				}
				const yearMerged = yearPrefix + year;
				if (unitCode !== yearMerged) {
					yearSubtitle = yearMerged;
				}

				if (regNumbersOnly.test(year) && yearSubtitle === '') {
					yearSubtitle = 'Year ' + year;
				}

				if (yearSubtitle !== '' && subtitle.search(yearSubtitle) < 0) {
					subtitle += ', ' + yearSubtitle;
				}
			}
		}

		//image
		let imgUrl = projectData.imgUrl;

		//student category display
		let studentAndGroup = '';
		if (activeCategory == 'students') {
			studentAndGroup = projectData.students[0].name;
			if (projectData.teamName) {
				studentAndGroup += ', ' + projectData.teamName;
			}
			if (projectData.students[0].heroImageUrl) {
				imgUrl = projectData.students[0].heroImageUrl;
			}
		} else {
			if (projectData.students.length > 1) {
				if (projectData.teamName) {
					studentAndGroup = projectData.teamName;
				} else if (projectData.groupName) {
					studentAndGroup = projectData.groupName;
				} else {
					studentAndGroup = 'Group Project';
				}
			} else {
				studentAndGroup = projectData.students.length > 0 ? projectData.students[0].name : '';
			}
		}

		const comingSoonMediaClass = projectData.comingSoon !== '1' ? '' : 'media--coming-soon';
		const comingSoonHtml =
			projectData.comingSoon !== '1'
				? ''
				: `
		<div class="coming-soon">
			<div class="inner">
				<span>Coming soon</span>
			</div>
		</div>
		`;

		const mediaInfoHtml = `
		<div class="media-wrapper" style="padding-bottom: ${(9 / 16) * 100}%">
			<div>
				<img class="lazyload ${comingSoonMediaClass}" data-src="${imgUrl + '?w=400&fm=jpg'}" alt="${projectData.title}">
			</div>
			<span class="award">AWARD</span>
			${comingSoonHtml}
		</div>
		<div class="info-wrapper">
			<span class="title">${projectData.title}</span>
			<span class="student">${studentAndGroup}</span>
			<span class="subtitle">${subtitle}</span>
		</div>
    `;

		let innerWrapperHtml;
		if (projectData.comingSoon !== '1') {
			innerWrapperHtml = `
				<a class="no-ul" href="${'https://' + projectData.meta.baseUrl + '/' + projectData.meta.unitSlug + '/' + projectData.slug}" target="_blank">
        ${mediaInfoHtml}
        </a>
		  `;
		} else {
			innerWrapperHtml = mediaInfoHtml;
		}

		div.innerHTML = `
      <div class="project-index-item ${isAward}">
      ${innerWrapperHtml}
      </div>
    `;

		this.element = div.firstElementChild as HTMLElement;

		if (this.element.querySelector('a')) {
			this.element.querySelector('a').addEventListener('click', e => {
				const target = e.target as HTMLAnchorElement;
				tracking.event('click', 'project-index', target.href);
				state.setValue(PROJECT_INDEX_SELECT, true);
			});
		}
	}
}

import Emitter from '@hellomonday/emitter';
import { showData } from '../modules/ShowData';

interface Callback {
	(result: any): void;
}

export const DROPDOWN_SELECT = 'dropdown_select';
export const DROPDOWN_OPEN = 'dropdown_open';

export class Dropdown extends Emitter {
	public element: HTMLElement;
	public selectedKey: '';
	private selected: HTMLElement;
	private button: HTMLElement;
	private list: HTMLElement;
	private listItems: NodeListOf<HTMLElement>;
	private dropdown: HTMLElement;
	private callback: Callback;

	constructor(element: HTMLElement) {
		super();
		this.element = element;
		this.selected = this.element.querySelector('.selected button');
		this.button = this.element.querySelector('.button');
		this.list = this.element.querySelector('.list');
		this.listItems = this.list.querySelectorAll('.list-item');
		this.dropdown = this.element.querySelector('.dropdown');

		this.init();
	}

	private init() {
		this.button.addEventListener('click', e => this.toggleDropdown(e));
		this.addListItemHandlers();
	}

	onListItemClick(e: any) {
		const el = e.target;
		const title = el.querySelector('.dropdown-title').innerHTML;
		this.updateSelectedItem(el.querySelector('.dropdown-title').innerHTML);
		this.selectedKey = el.dataset.value;
		this.emit(DROPDOWN_SELECT, { value: this.selectedKey, title });

		this.hideDropdown();
	}

	toggleDropdown(e: any) {
		if (this.element.classList.contains('active')) {
			this.hideDropdown();
		} else {
			this.showDropdown();
		}
	}

	setItemTemplate(key, label, sublabel) {
		return `
				<li class="list-item">
				<button class="button-item" data-value="${key}" tabindex="-1" role="listitem">
				<span class="span dropdown-title type-p--small">${label}</span>
				<span class="span dropdown-subtitle type-p--small">${sublabel}</span>
				</button>
				</li>
		`;
	}

	enable() {
		this.selected.setAttribute('tabindex', '0');
	}

	disable() {
		this.element.classList.remove('focussed');
		this.selected.setAttribute('tabindex', '-1');
	}

	hide() {
		this.element.style.display = 'none';
	}

	show() {
		this.element.style.display = 'block';
	}

	public hideDropdown() {
		this.element.classList.remove('active');
		const buttons = this.element.querySelectorAll('button');
		buttons.forEach(btn => {
			if (!btn.parentElement.classList.contains('selected')) {
				btn.setAttribute('tabindex', '-1');
			}
		});
		this.element.classList.remove('focussed');
	}

	showDropdown() {
		const buttons = this.element.querySelectorAll('button');
		buttons.forEach(btn => {
			if (!btn.parentElement.classList.contains('selected')) {
				btn.setAttribute('tabindex', '0');
			}
		});
		this.element.classList.add('active');
		this.emit(DROPDOWN_OPEN, { value: this });
	}

	onFocus() {
		this.element.classList.add('focussed');
	}

	onBlur(e: any) {
		const target = e.target as HTMLElement;
		const nextEl = target.parentElement.nextElementSibling;
		if (nextEl === null || e.relatedTarget === null || !e.relatedTarget.classList.contains('button-item')) {
			this.hideDropdown();
			this.element.classList.remove('focussed');
		}
	}

	addListItemHandlers() {
		const buttons = this.element.querySelectorAll('button');
		buttons.forEach(btn => {
			btn.addEventListener('blur', this.onBlur.bind(this));
			btn.addEventListener('focus', this.onFocus.bind(this));
		});

		this.listItems.forEach(el => {
			el.addEventListener('click', e => this.onListItemClick(e));
		}, this);
	}

	removeListItemHandlers() {
		this.listItems.forEach(el => {
			el.removeEventListener('click', e => this.onListItemClick(e));
		}, this);
	}

	public updateSelectedItem(value) {
		const buttonTitle = this.selected.querySelector('.dropdown-title');
		buttonTitle.innerHTML = value;
	}

	public populate(values: any) {
		this.removeListItemHandlers();
		this.selectedKey = '';
		this.list.innerHTML = '';
		for (const item of values) {
			let listItem = this.setItemTemplate(item.key, item.label, item.sublabel);
			this.list.insertAdjacentHTML('beforeend', listItem);
		}
		this.selected.querySelector('.dropdown-title').innerHTML = values[0].label;
		this.listItems = this.list.querySelectorAll('.list-item');
		this.addListItemHandlers();
	}

	public close() {
		this.hideDropdown();
	}

	public destroy() {
		this.button.removeEventListener('click', e => this.toggleDropdown(e));
		this.removeListItemHandlers();
	}
}

export class DropdownItem {
	public key: string;
	public label: string;
	public sublabel: string;

	constructor(key: string, label: string, sublabel: string = '') {
		this.key = key;
		this.label = label;
		this.sublabel = sublabel;
	}
}

import { Frustum, Mesh, Object3D, PerspectiveCamera, Vector3 } from 'three';
import * as THREE from 'three';
import { WindowManager } from './WindowManager';

export function getCameraDistanceToFill(camera: PerspectiveCamera, object: Object3D) {
	const size = (object as Mesh).geometry.boundingBox.getSize(new Vector3());
	const width = size.x;
	const height = size.y;
	const objectAspect = width / height;
	const objectSize = camera.aspect > objectAspect ? width : height;
	const dist = (objectSize / 2) * Math.tan((camera.fov / 2) * (Math.PI / 180));
	return dist / (camera.aspect > objectAspect ? camera.aspect : 1);
}

export function isInFrustum(camera: PerspectiveCamera, object: Object3D) {
	const frustum = new THREE.Frustum();
	const cameraViewProjectionMatrix = new THREE.Matrix4();
	camera.updateMatrixWorld(); // make sure the camera matrix is updated
	camera.matrixWorldInverse.getInverse(camera.matrixWorld);
	cameraViewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
	frustum.setFromProjectionMatrix(cameraViewProjectionMatrix);
	return frustum.intersectsObject(object);
}

export function getPointInBetweenByLen(pointA, pointB, length) {
	const dir = pointB
		.clone()
		.sub(pointA)
		.normalize()
		.multiplyScalar(length);
	return pointA.clone().add(dir);
}

export function getStyle(oElm, strCssRule) {
	let strValue = '';
	if (document.defaultView && document.defaultView.getComputedStyle) {
		strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
	} else if (oElm.currentStyle) {
		strCssRule = strCssRule.replace(/\-(\w)/g, function(strMatch, p1) {
			return p1.toUpperCase();
		});
		strValue = oElm.currentStyle[strCssRule];
	}
	return strValue;
}

export function isTouch() {
	let hasCursor = matchMedia && matchMedia('(pointer:fine)').matches && screen.width > 1024;
	let isTouch = ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) && !hasCursor;
	return isTouch;
}

export function clamp(min, max, v) {
	return Math.min(max, Math.max(min, v));
}

export function detectTrackPad(e) {
	let isTrackpad = false;
	if (e.wheelDeltaY) {
		if (e.wheelDeltaY === e.deltaY * -3) {
			isTrackpad = true;
		}
	} else if (e.deltaMode === 0) {
		isTrackpad = true;
	}
	return isTrackpad;
}

export function isFirefox() {
	return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function htmlDecode(input) {
	var e = document.createElement('div');
	e.innerHTML = input;
	return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function checkExternalLink(link) {
	return !link.href.match(/^mailto\:/) && link.hostname != location.hostname && !link.href.match(/^javascript\:/) && !link.href.match(/^$/);
}

export function connectDeviceOrientation(onSuccess) {
	if (window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
		window.DeviceOrientationEvent.requestPermission()
			.then(function(response) {
				if (response == 'granted') {
					if (onSuccess) {
						onSuccess();
					}
				}
			})
			.catch(function(error) {
				console.error('THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error);
			});
	} else {
		window.addEventListener('devicemotion', event => {
			if (event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma) {
				if (onSuccess) {
					onSuccess();
				}
			}
		});
	}
}

export function offset(el, scrollEl = document.documentElement) {
	const rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || scrollEl.scrollLeft,
		scrollTop = window.pageYOffset || scrollEl.scrollTop;
	return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function setPageTitle(title: string) {
	document.querySelector('title').innerHTML = title;
}

export function shortestAngle(delta: number) {
	let fDelta = delta;
	if (Math.abs(fDelta) > Math.PI) {
		if (fDelta >= 0) {
			fDelta -= Math.PI * 2;
		} else {
			fDelta += Math.PI * 2;
		}
	}
	return fDelta;
}

/**
 * Converts :hover CSS to :active CSS on mobile devices.
 * Otherwise, when tapping a button on a mobile device, the button stays in
 * the :hover state until the button is pressed.
 *
 * Inspired by: https://gist.github.com/rcmachado/7303143
 * @author  Michael Vartan <michael@mvartan.com>
 * @version 1.0
 * @date    2014-12-20
 */
export function hoverTouchUnstick() {
	// Check if the device supports touch events
	if ('ontouchstart' in document.documentElement) {
		// Loop through each stylesheet
		for (var sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
			var sheet = document.styleSheets[sheetI];
			// Verify if cssRules exists in sheet
			if (sheet.cssRules) {
				// Loop through each rule in sheet
				for (var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
					var rule: any = sheet.cssRules[ruleI];
					// Verify rule has selector text
					if (rule.selectorText) {
						// Replace hover psuedo-class with active psuedo-class
						rule.selectorText = rule.selectorText.replace(':hover', ':active');
					}
				}
			}
		}
	}
}

export function shuffleArray(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export function dynamicSort(property) {
	var sortOrder = 1;

	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function(a, b) {
		if (sortOrder == -1) {
			return b[property].localeCompare(a[property]);
		} else {
			return a[property].localeCompare(b[property]);
		}
	};
}

import { ShaderMaterial, NormalBlending, Vector2, Color, UniformsUtils, UniformsLib } from 'three';

// // @ts-ignore
// import * as fragment from './LambertGradientFragment.glsl';
// // @ts-ignore
// import * as vertex from './LambertDefaultVertex.glsl';

// @ts-ignore
import * as vertex from './GradientMaterialVertex.glsl';
// @ts-ignore
import * as fragment from './GradientMaterialFragment.glsl';

import { Globals } from '../data/Globals';

export class LinearGradientMaterial extends ShaderMaterial {
	private _params;
	private _colors;

	constructor(colors, stops, disableShadows: boolean = false) {
		super({
			uniforms: UniformsUtils.merge([
				UniformsLib['lights'],
				{
					lightIntensity: {
						value: 1.0
					},
					gradientStartPos: {
						value: new Vector2(-1, 1) //new Vector2(0.4, 1)
					},
					gradientEndPos: {
						value: new Vector2(0.5, 0)
					},
					numStops: {
						value: 4
					},
					saturation: {
						value: 0
					},
					inactiveColor: {
						value: new Color('#333333')
					},
					disableShadows: {
						value: disableShadows
					},
					// colors: {
					// 	value: [new Color(0xff0000), new Color(0x00ff00)]
					// },
					color1: {
						value: new Color('#' + colors[0]) // new Color(0xFFFFFF * Math.random())//
					},
					color2: {
						value: new Color('#' + colors[1]) // new Color(0xFFFFFF * Math.random())//
					},
					color3: {
						value: new Color('#' + colors[2]) // new Color(0xFFFFFF * Math.random())//
					},
					color4: {
						value: new Color('#' + colors[3]) // new Color(0xFFFFFF * Math.random())//
					},
					stops: {
						value: stops
					}
				}
			]),

			transparent: true,
			lights: true,
			dithering: true,
			blending: NormalBlending,

			vertexShader: vertex,

			fragmentShader: fragment
		});

		//@ts-ignore
		//this.uniforms = UniformsUtils.merge([this.uniforms, UniformsLib["lights"]]);

		// //@ts-ignore
		// console.log(this.uniforms);

		this._colors = ['#' + colors[0], '#' + colors[1], '#' + colors[2], '#' + colors[3]];

		this._params = {
			startPos: {
				x: 0,
				y: 1
			},
			endPos: {
				x: 0.47,
				y: 0
			},
			stop1: stops[0],
			stop2: stops[1],
			stop3: stops[2],
			stop4: stops[3]
		};

		if (Globals.GUI_ENABLED) {
			this.addGui();
		}
	}

	private addGui = () => {
		let top = Globals.GUI.addFolder('Gradient #' + Math.random());

		//@ts-ignore
		top.addVector('Start position', this.uniforms.gradientStartPos.value);
		//@ts-ignore
		top.addVector('End position', this.uniforms.gradientEndPos.value);

		//@ts-ignore
		top.add(this._params, 'stop1', 0, 1)
			.onChange(this.update)
			.step(0.01)
			.listen();

		//@ts-ignore
		top.add(this._params, 'stop2', 0, 1)
			.onChange(this.update)
			.step(0.01)
			.listen();

		//@ts-ignore
		top.add(this._params, 'stop3', 0, 1)
			.onChange(this.update)
			.step(0.01)
			.listen();

		//@ts-ignore
		top.add(this._params, 'stop4', 0, 1)
			.onChange(this.update)
			.step(0.01)
			.listen();

		//@ts-ignore
		// let conf = {color: this._colors[0]};
		// top.addColor(conf, 'color').onChange((colorValue) => {
		// 	//@ts-ignore
		// 	this.uniforms.color1.value = new Color(colorValue)
		// });
		//
		// //@ts-ignore
		// conf = {color: this._colors[1]};
		// top.addColor(conf, 'color').onChange((colorValue) => {
		// 	//@ts-ignore
		// 	this.uniforms.color2value = new Color(colorValue)
		// });
		//
		// //@ts-ignore
		// conf = {color: this._colors[2]};
		// top.addColor(conf, 'color').onChange((colorValue) => {
		// 	//@ts-ignore
		// 	this.uniforms.color1.value = new Color(colorValue)
		// });

		// top.open();
	};

	private update = () => {
		//@ts-ignore
		this.uniforms.stops.value[0] = this._params.stop1;
		//@ts-ignore
		this.uniforms.stops.value[1] = this._params.stop2;
		//@ts-ignore
		this.uniforms.stops.value[2] = this._params.stop3;
		//@ts-ignore
		this.uniforms.stops.value[3] = this._params.stop4;

		//@ts-ignore
		this.uniformsNeedUpdate = true;
	};

	get color1() {
		//@ts-ignore
		return this.uniforms.color1;
	}

	get color2() {
		//@ts-ignore
		return this.uniforms.color2;
	}

	get color3() {
		//@ts-ignore
		return this.uniforms.color3;
	}

	get color4() {
		//@ts-ignore
		return this.uniforms.color4;
	}

	// get scaleFactor1() {
	// 	//@ts-ignore
	// 	return this.uniforms.scaleFactor1.value;
	// }
	//
	// set scaleFactor1(value: number) {
	// 	//@ts-ignore
	// 	this.uniforms.scaleFactor1.value = value;
	// }
}

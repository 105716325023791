import * as THREE from 'three';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { Globals } from '../data/Globals';
import { World } from '../3d/World';
import Resources from '../3d/resources/Resources';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MediaMaterial } from './MediaMaterial';

export interface IResourcesLoaderSource {
	name: string;
	source: string;
	data?: any;
}

export default class ResourceLoader {
	public signalComplete: Signal = new Signal();
	public mediaMaterial: MediaMaterial = new MediaMaterial();

	private _loaders: Array<any> = [];
	private _GLTFLoader: GLTFLoader = new GLTFLoader();
	private _dracoLoader: DRACOLoader = new DRACOLoader();

	private _uploadedTextures: THREE.Texture[] = [];
	private _uploadedTextureMaterials: THREE.Material[] = [];

	private _resourcesToLoad: number = 0;
	private _resourcesLoaded: number = 0;
	private _allResourcesLoaded: boolean = false;

	private _resources: Array<IResourcesLoaderSource> = [];

	private _world: World;

	constructor(world: World) {
		this._resourcesToLoad = Resources.data.length;

		this._world = world;
		// THREE.DefaultLoadingManager.setURLModifier(this.urlModifierCallback);
		this._dracoLoader.setDecoderPath('/assets/3d/draco/');
		this._dracoLoader.setDecoderConfig({ type: 'js' });

		this._GLTFLoader.setDRACOLoader(this._dracoLoader);

		this._loaders.push({
			extensions: ['glb', 'gltf'],
			action: resource => {
				this._GLTFLoader.load(
					resource.source,
					_data => {
						this.modelLoaded(resource, _data);
					},
					null,
					this.onModelLoadError
				);
			}
		});

		let textureLoader = new THREE.TextureLoader();
		this._loaders.push({
			extensions: ['jpg', 'jpeg', 'png'],
			action: resource => {
				textureLoader.load(
					resource.source,
					texture => {
						this.textureLoaded(resource, texture);
					},
					null,
					this.onTextureLoadError
				);
			}
		});
	}
	private onModelLoadError = e => {
		console.error(e);
	};

	private onTextureLoadError = e => {
		console.error('Error loading image: ' + e.target.currentSrc);
	};

	public load = () => {
		for (let resource of Resources.data) {
			let extension = resource.source.match(/\.([0-9a-z]+)$/)[1];

			if (extension === 'mp4') {
				console.log('mp4', resource.source);
				this.mediaMaterial.loadVideo(resource.source);
				this._resources.push(resource);
				this.textureLoaded(resource, this.mediaMaterial.videoTexture);
				this.mediaMaterial.playVideo();
			} else {
				let loader = this.getLoader(extension);
				loader.action(resource);
			}
		}
	};

	private getLoader = (extension: string) => {
		let l = this._loaders.length;

		for (let i = 0; i < l; i++) {
			if (this._loaders[i].extensions.includes(extension)) {
				return this._loaders[i];
			}
		}

		throw new Error('No loader for extension: ' + extension + ' is set up');
	};

	private modelLoaded = (resource: IResourcesLoaderSource, data: GLTF) => {
		this._resourcesLoaded++;

		data.scenes.forEach(scene =>
			scene.traverse(obj => {
				// @ts-ignore
				if (obj.isMesh) {
					// @ts-ignore
					if (obj.material && (obj.material as THREE.MeshStandardMaterial).emissiveMap) {
						// @ts-ignore
						let text = obj.material.emissiveMap as THREE.Texture;

						if (!this._uploadedTextures.includes(text)) {
							this._uploadedTextures.push(text);
							// @ts-ignore
							this._uploadedTextureMaterials.push(obj.material);
						}
					}
				}
			})
		);

		resource.data = data;
		this._resources.push(resource);

		// emitter.emit(EVENTS.fileLoaded, [resource, data]);
		this.checkAllResourcesDone();
	};

	private textureLoaded = (resource: IResourcesLoaderSource, texture: THREE.Texture) => {
		texture.encoding = THREE.sRGBEncoding;

		this._resourcesLoaded++;
		this._uploadedTextures.push(texture);
		// emitter.emit(EVENTS.fileLoaded, [resource, texture]);

		resource.data = texture;
		this._resources.push(resource);

		this.checkAllResourcesDone();
	};

	private checkAllResourcesDone = () => {
		if (this._resourcesLoaded === this._resourcesToLoad) {
			this._dracoLoader.dispose();

			if (!Globals.IS_SAFARI) {
				this.uploadTexture();
			}

			this._allResourcesLoaded = true;
			this.checkResourcesLoaded();
		}
	};

	public uploadTexture = () => {
		this._uploadedTextures.forEach((tex, index) => {
			this._world.renderer.initTexture(tex);
		});
	};

	public getResource = (id: string) => {
		let l = this._resources.length;
		let found = false;

		for (let i = 0; i < l; i++) {
			if (id === this._resources[i].name) {
				return this._resources[i].data;
			}
		}

		if (!found) {
			console.log('No resources with id: ' + id + ' exists');
			return null;
		}
	};

	private checkResourcesLoaded = () => {
		if (this._allResourcesLoaded) {
			this.signalComplete.dispatch();
		}
	};

	get resources() {
		return this._resources;
	}
}

import gsap from 'gsap/gsap-core';

class Logo {
	private element: HTMLElement;
	private logoText: HTMLElement;

	constructor(element: HTMLElement) {
		this.element = element;
		this.logoText = this.element.querySelector('.main-logo .text svg');
	}

	public minimize() {
		gsap.to(this.logoText, 0.5, { x: -73, y: 14 });
	}
}

export const logo = new Logo(document.body.querySelector('.main-logo'));

import 'lazysizes';
import { WindowManager } from './utils/WindowManager';
import navaid from 'navaid';
import { BASE_PATH, CURRENT_3D_VIEW, CURRENT_OVERLAY, FIRST_ROUTE, LAST_OVERLAY, LOAD_COMPLETE, LOAD_PROGRESS, LAST_3D_PATH, OVERLAY_LAYER, BROWSER_MODULE, PROJECT_INDEX } from './utils/Contants';
import State from '@hellomonday/state';
import swapDOM from '@hellomonday/swap-dom';
import { Globals } from './data/Globals';
import { colorizer } from './modules/Colorizer';
import { browserModule } from './modules/BrowserModule';
import { projectIndexPage } from './templates/ProjectIndexPage';
import ResourceLoader from './loaders/ResourceLoader';
import { clickBlocker, UNBLOCK } from './modules/ClickBlocker';
import { hoverTouchUnstick, isTouch, setPageTitle } from './utils/Helpers';
import { tracking } from './utils/Tracking';
import { World } from './3d/World';
import gsap from 'gsap/gsap-core';

export const router = navaid('/');
export const state = new State();

class Main {
	private pendingRoute: boolean;

	constructor() {
		this.initialize();
	}

	private async initialize() {
		state.setValue(CURRENT_3D_VIEW, null);
		state.setValue(OVERLAY_LAYER, null);

		Globals.WORLD = new World(document.body.querySelector('.World'));
		Globals.RESOURCE_LOADER = new ResourceLoader(Globals.WORLD);
		Globals.RESOURCE_LOADER.signalComplete.add(this.resourcesLoaded);
		Globals.RESOURCE_LOADER.load();

		Globals.MAIN_LOGO = document.body.querySelector('.main-logo');
		WindowManager.getInstance();

		// await introPage.animateIn();
		// await showData.load();
		//
		// gallery3d.initialize();
		colorizer.initialize();
		projectIndexPage.initialize();

		this.favicon();
	}

	private resourcesLoaded = () => {
		Globals.WORLD.start();
		//browserModule.init();
		this.initSite();
	};

	private initSite() {
		state.on(CURRENT_OVERLAY, this.onOverlaySwitch.bind(this));
		state.on(CURRENT_3D_VIEW, this.on3dViewSwitch.bind(this));

		this.initRouter();

		// unitBrowser.on(LOAD_PROGRESS, event => {
		// 	introPage.progress = event.progress * 0.5 + 0.5;
		// });
		//
		// unitBrowser.on(LOAD_COMPLETE, () => {
		// 	introPage.loadComplete();
		// });

		addEventListener('click', e => {
			const el = (e.target as HTMLElement).closest('a');
			if (el && location.pathname == el.pathname) e.preventDefault();
			if (el && el.classList.contains('close-overlay-button')) {
				e.preventDefault();
				const previous3dPath = state.getValue(LAST_3D_PATH);
				if (!previous3dPath) {
					router.route(BASE_PATH);
				} else {
					router.route(previous3dPath);
				}
			}
		});

		WindowManager.signalResize.add(this.resize);
		this.resize();
	}

	private initRouter() {
		state.setValue(FIRST_ROUTE, true);

		router
			// Index
			.on('/', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_3D_VIEW, {
						id: BROWSER_MODULE,
						path: '/',
						slug: '/'
					});
					state.setValue(CURRENT_OVERLAY, null);
				}
			})

			// Project Index
			.on('/project-index/:filter?', params => {
				if (this.onRouteStart()) {
					state.setValue(CURRENT_OVERLAY, {
						id: PROJECT_INDEX,
						instance: projectIndexPage,
						title: 'Project Index',
						path: BASE_PATH + 'project-index/' + params.filter,
						filterId: params.filter,
						transition: 'slide'
					});
				}
			});

		router.listen();
	}

	private onRouteStart() {
		if (clickBlocker.blocked) {
			this.pendingRoute = true;
			return false;
		} else {
			return true;
		}
	}

	// State handler for Overlay views
	async onOverlaySwitch(stateItem) {
		if (!stateItem.previous && !stateItem.current) {
			return;
		}

		clickBlocker.block();

		// Handle previous state
		if (stateItem.previous) {
			state.setValue(LAST_OVERLAY, stateItem.previous);
			if (stateItem.previous.transition == 'slide') {
				browserModule.slideDown();
				// mainMenu.slideDown();
			}
			if (stateItem.previous.instance) {
				await stateItem.previous.instance.animateOut();
			}
			//document.querySelector(OVERLAY_LAYER).innerHTML = "";
		}

		// Handle current state
		if (stateItem.current) {
			if (state.getValue(FIRST_ROUTE)) {
				// if (isTouch()) {
				// 	introPage.loadComplete();
				// } else {
				// 	await introPage.animateOut();
				// }
			} else {
				if (stateItem.current.instance) {
					setPageTitle(stateItem.current.title + ' | ' + document.body.dataset.pagetitle);
				} else {
					await swapDOM(stateItem.current.path, OVERLAY_LAYER);
					tracking.pageView(stateItem.current.path);
				}
				colorizer.update();
			}
			if (!stateItem.current.instance) {
				stateItem.current.instance = new stateItem.current.class(document.querySelector(OVERLAY_LAYER).firstElementChild);
			}
			stateItem.current.instance.animateIn();
			if (stateItem.current.transition == 'slide') {
				//mainMenu.slideUp();
				await browserModule.slideUp();
			}
		}
		state.setValue(FIRST_ROUTE, false);
		clickBlocker.unblock();
	}

	// State handler for 3d views
	async on3dViewSwitch(stateItem) {
		clickBlocker.block();
		// gallery3d.show();
		// gallery3d.start();

		console.log(document.body.dataset.pagetitle);
		setPageTitle(document.body.dataset.pagetitle);

		// await unitBrowser.load();
		// await unitBrowser.animateIn();

		await browserModule.load();

		state.setValue(LAST_3D_PATH, stateItem.current.path);
		state.setValue(FIRST_ROUTE, false);
		tracking.pageView(stateItem.current.path);
		clickBlocker.unblock();
		if (stateItem.current.onComplete) {
			stateItem.current.onComplete();
		}
	}

	public resize = () => {
		Globals.WORLD.resize();
	};

	private favicon() {
		const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

		if (darkModeMediaQuery.matches) {
			const favicon = document.getElementById('favicon');
			favicon.setAttribute('href', '/assets/images/favicons/favicon_32x32_white.png');
		}
	}
}

window.onload = () => {
	(window as any).Main = new Main();
};

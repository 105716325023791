import { IndexBlockData } from './IndexMenu';
import { gsap } from 'gsap';
import SplitText from '../../lib/com/greensock/gsap-bonus/SplitText';
import { Globals } from '../data/Globals';

export class IndexContentDisplay {
	private _element: HTMLDivElement;
	private _innerContainer: HTMLDivElement;
	private _commingSoon: HTMLDivElement;
	private _title: HTMLAnchorElement;
	private _date: HTMLDivElement;

	private _splitDate;
	private _splitTitle;
	private _splitCommingSoon;

	private _ctaIcon;

	constructor(element: HTMLDivElement, data: IndexBlockData, promoted: boolean) {
		this._element = element;

		if (promoted) {
			this._element.classList.add('promoted');
		}

		// let dot = document.createElement('div');
		// dot.classList.add('dot');
		// element.appendChild(dot);

		if (promoted) {
			this._commingSoon = document.createElement('div');
			this._commingSoon.classList.add('promotion');
			this._commingSoon.innerText = 'Currently on'; //'Currently on'; //'Coming soon'; //'Latest on';
			element.appendChild(this._commingSoon);

			this._splitCommingSoon = new SplitText(this._commingSoon);
			gsap.set(this._splitCommingSoon.chars, { opacity: 0, y: 10 });
			gsap.set(this._commingSoon, { opacity: 1 });
		}

		this._title = document.createElement('a');
		this._title.classList.add('title');
		this._title.setAttribute('aria-label', data.title.replace('<br>', '').replace('<br/>', ''));
		this._title.setAttribute('href', data.link);
		this._title.setAttribute('target', '_blank');
		this._title.setAttribute('tabindex', '-1');

		let parts = data.title.split('<br>');

		let l = parts.length;

		for (let i = 0; i < l; i++) {
			let p = document.createElement('p');
			p.innerText = parts[i];
			p.setAttribute('aria-hidden', 'true');
			this._title.appendChild(p);

			if (l > 1) {
				this._title.appendChild(document.createElement('br'));
			}
		}

		// this._title.innerText = data.title;

		this._splitTitle = new SplitText(this._title);
		gsap.set(this._splitTitle.chars, { opacity: 0 }); //, y: 10});
		gsap.set(this._title, { opacity: 1 }); //, x: -10});

		element.appendChild(this._title);

		this._date = document.createElement('div');
		this._date.classList.add('date');
		this._date.innerText = data.date;
		this._splitDate = new SplitText(this._date);
		gsap.set(this._splitDate.chars, { opacity: 0 }); //, y: 10});
		gsap.set(this._date, { opacity: 1 }); //, x: -10})
		element.appendChild(this._date);

		// if (Globals.IS_TOUCH_DEVICE) {
		// 	this._ctaIcon = document.body.querySelector('.cursor svg').cloneNode(true);
		// 	this._ctaIcon.classList.add('ctaIcon');
		// 	gsap.set(this._ctaIcon, {scale: 0.5, transformOrigin: '50% 50%'});
		// 	element.appendChild(this._ctaIcon);
		// }

		this._title.addEventListener('focus', this.onFocus.bind(this));
	}

	private onFocus = () => {};

	public setTabIndex = (index: number) => {
		this._title.setAttribute('tabindex', `${index}`);
	};

	public toggle = (state: boolean) => {
		if (this._commingSoon) {
			gsap.to(this._splitCommingSoon.chars, {
				stagger: state ? 0.02 : 0,
				opacity: state ? 1 : 0,
				y: state ? 0 : 10,
				ease: 'power2.inOut',
				duration: state ? 0.3 : 0.2,
				delay: state ? 0.2 : 0,
				overwrite: true
			});
		}

		gsap.to(this._splitTitle.chars, {
			stagger: state ? 0.02 : 0,
			opacity: state ? 1 : 0,
			// y: state ? 0 : -10,
			ease: 'power2.inOut',
			duration: state ? 0.3 : 0.2,
			delay: state ? 0.3 : 0,
			overwrite: true
		});

		gsap.to(this._splitDate.chars, {
			stagger: state ? 0.02 : 0,
			opacity: state ? 1 : 0,
			// y: state ? 0 : 10,
			ease: 'power2.inOut',
			duration: state ? 0.3 : 0.2,
			delay: state ? 0.3 : 0,
			overwrite: true
		});

		// gsap.to(this._title, {
		// 	x: state ? 0 : -10,
		// 	ease: 'power2.inOut',
		// 	duration: 0.6,//state ? 0.3 : 0.2,
		// 	delay: state ? 0.3 : 0,
		// 	overwrite: true
		// });
		//
		// gsap.to(this._date, {
		// 	x: state ? 0 : -10,
		// 	ease: 'power2.inOut',
		// 	duration: state ? 0.3 : 0.2,
		// 	delay: state ? 0.3 : 0,
		// 	overwrite: true
		// });

		// if (Globals.IS_TOUCH_DEVICE) {
		// 	gsap.to(this._ctaIcon, {
		// 		scale: state ? 1 : 0.5,
		// 		opacity: state ? 1 : 0,
		// 		ease: 'power2.inOut',
		// 		duration: state ? 0.3 : 0.2,
		// 		delay: state ? 1.2 : 0,
		// 		overwrite: true
		// 	})
		// }
	};
}

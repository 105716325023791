import { Color, LinearFilter, MeshBasicMaterial, NearestFilter, Texture, VideoTexture } from 'three';
import * as THREE from 'three';

export class MediaMaterial extends MeshBasicMaterial {
	public videoElement: HTMLVideoElement;
	public videoLoaded: boolean;
	private videoPlaying: boolean = false;
	private url: string;
	private videoSrcElement: HTMLSourceElement;
	private videoUrl: string;
	private autoplayVideo: boolean;
	public videoTexture: Texture;

	constructor(params = null) {
		super(params);
	}

	public loadVideo(videoUrl) {
		if (videoUrl == this.url) {
			return;
		}

		this.url = videoUrl;
		this.videoUrl = videoUrl;
		this.setupVideoElement();
		this.videoTexture = new Texture(this.videoElement);

		return new Promise(resolve => {
			this.videoElement.addEventListener('loadeddata', e => {
				if (this.videoElement.readyState >= 1) {
					this.onVideoLoad();
					resolve();
				}
			});

			this.videoElement.addEventListener('error', e => {
				console.log('error', this.videoElement.error);
				resolve();
			});

			this.fetchVideoUrl();
		});
	}

	private setupVideoElement() {
		this.videoSrcElement = document.createElement('source') as HTMLSourceElement;
		this.videoSrcElement.type = 'video/mp4';
		this.videoElement = document.createElement('video') as HTMLVideoElement;
		this.videoElement.autoplay = true;
		this.videoElement.muted = true;
		this.videoElement.preload = 'metadata';
		this.videoElement.setAttribute('playsinline', '');
		this.videoElement.setAttribute('loop', '');
		this.videoElement.loop = true;
		this.videoElement.crossOrigin = 'anonymous';
		this.videoElement.appendChild(this.videoSrcElement);
	}

	public async fetchVideoUrl() {
		// const map = state.getValue(VIDEO_URL_MAP);
		if (this.videoElement) {
			if (this.videoUrl) {
				this.videoSrcElement.src = this.videoUrl;
				this.videoElement.load();
			} else {
				await fetch(this.videoUrl, {
					method: 'HEAD'
				}).then(response => {
					const url = response.url + '#t=0.1';
					this.videoSrcElement.src = url;
					this.videoUrl = url;
					this.videoElement.load();
				});
			}
		}
	}

	private onVideoLoad() {
		this.videoTexture.minFilter = THREE.LinearFilter;
		this.videoTexture.magFilter = THREE.LinearFilter;
		this.videoTexture.format = THREE.RGBFormat;
		this.videoTexture.generateMipmaps = false;
		this.videoLoaded = true;
		this.render(true);
		if (this.autoplayVideo) {
			this.playVideo();
		}
	}

	public playVideo() {
		if (this.videoElement && this.videoLoaded && !this.videoPlaying) {
			this.videoPlaying = true;
			this.videoElement.play();
		} else {
			this.autoplayVideo = true;
		}
	}

	public pauseVideo() {
		this.autoplayVideo = false;
		if (this.videoElement && this.videoLoaded && this.videoPlaying) {
			this.videoPlaying = false;
			this.videoElement.pause();
		}
	}

	public render(force = false) {
		if (this.videoTexture && this.videoLoaded && (this.videoPlaying || force)) {
			this.videoTexture.needsUpdate = true;
		}
	}

	public clear() {
		this.url = null;
		this.videoLoaded = false;

		if (this.videoTexture) {
			this.videoTexture.dispose();
		}
		if (this.videoElement) {
			this.pauseVideo();
			this.videoUrl = null;
			if (this.videoSrcElement) {
				this.videoSrcElement.setAttribute('src', '');
			}
			this.videoElement.load();
		}
	}
}

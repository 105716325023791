import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class WheelInput extends Signal {
	private _active: boolean = true;

	constructor() {
		super();

		window.addEventListener('wheel', this.update, { passive: false });
	}

	private update = e => {
		if (!this._active) {
			return;
		}

		e.preventDefault();

		if (this.getNumListeners() > 0) {
			this.dispatch({
				deltaY: e.deltaY
			});
		}
	};

	public disable = () => {
		this._active = false;
	};

	public enable = () => {
		this._active = true;
	};
}

import gsap from 'gsap';
import * as THREE from 'three';
import { IndexBlockData, IndexMenu } from './IndexMenu';
import { CSS2DObject } from 'three/examples//jsm/renderers/CSS2DRenderer.js';
import { LinearGradientMaterial } from '../shaders/LinearGradientMaterial';

// @ts-ignore
import * as fragment from '../shaders/LambertGradientFragment.glsl';
import { IndexContentDisplay } from './IndexContentDisplay';
import { Globals } from '../data/Globals';
import { degreesToRadians } from '../../lib/com/hellomonday/utils/MathUtils';
// import {GlowMaterial} from "../shaders/GlowMaterial";
// import {SubdivisionModifier} from "../../lib/com/three/modifiers/SubdivisionModifier";

const overwrite: boolean = true;

export class IndexBlock {
	private _container: THREE.Group = new THREE.Group();
	private _innerContainer: THREE.Group = new THREE.Group();
	private _block: THREE.Mesh;
	private _data: IndexBlockData;
	private _id: number;
	private _position: number = 0;

	private _highlightPercent = { value: 0 };

	private _material;
	private _material2;

	private _colors = [];

	private _rotationContainer: THREE.Group = new THREE.Group();

	private _indexMenu: IndexMenu;

	private _contentDisplay: IndexContentDisplay;
	private _2dContainer: HTMLDivElement = document.createElement('div');
	private _object2d: CSS2DObject;

	private _promoted: boolean = false;

	private _link: string;

	private _glowIntensity: number;

	private _image: THREE.Mesh;

	private _highlighted: boolean = false;

	private _helper;

	private _glow;

	constructor(data: IndexBlockData, id: number, indexMenu: IndexMenu) {
		this._data = data;
		this._id = id;
		this._indexMenu = indexMenu;
		this._link = data.link;

		this._glowIntensity = data.glowIntensity;

		//@ts-ignore
		this._promoted = data.promoted === 'true';

		this._container.add(this._rotationContainer);
		this._rotationContainer.add(this._innerContainer);

		let colors = [data.gradientColor1.hex, data.gradientColor2.hex, data.gradientColor3.hex, data.gradientColor4.hex];
		let stops = [data.gradientColor1.step, data.gradientColor2.step, data.gradientColor3.step, data.gradientColor4.step];

		this._colors.push(new THREE.Color('#' + data.gradientColor1.hex));
		this._colors.push(new THREE.Color('#' + data.gradientColor2.hex));
		this._colors.push(new THREE.Color('#' + data.gradientColor3.hex));
		this._colors.push(new THREE.Color('#' + data.gradientColor4.hex));

		this._material = new LinearGradientMaterial(colors, stops);
		this._material2 = new LinearGradientMaterial(colors, stops, true);

		// let geometry = new THREE.BoxGeometry(0.47, 4.8, 1);//1.5);
		let geometry = new THREE.BoxGeometry(0.35, 4.8, 1.4); //1.5);

		let materials = [this._material2, this._material, this._material, this._material, this._material, this._material];

		this._block = new THREE.Mesh(geometry, materials);
		// this._block.position.x = -(0.35 * 0.5);
		this._block.position.z = -0.5; //-0.75;
		this._block.position.y = 2.4;
		this._block.name = 'Block_' + id;
		//this._block.toggleHover = this.toggleHover;

		this._block.receiveShadow = true;
		this._block.castShadow = true;

		this._innerContainer.z = 0.5; //0.75;
		this._innerContainer.add(this._block);

		this._2dContainer.className = 'container2d';
		this._object2d = new CSS2DObject(this._2dContainer);
		// this._object2d.position.set(this.promoted ? -0.7 : 0.17, 2.4, 0);//this.promoted ? -0.3 : -1); // this.promoted ? -1.1 :
		// this._object2d.position.set(0.17, 2.4, 0); //this.promoted ? -0.3 : -1); // this.promoted ? -1.1 :
		// this._object2d.position.set(0.17, 3, 0); //this.promoted ? -0.3 : -1); // this.promoted ? -1.1 :
		this._object2d.position.set(0.4, 3, 0);
		this._rotationContainer.add(this._object2d);

		// if (Globals.GUI_ENABLED) {
		// 	Globals.GUI.addObject3D('2d Object ' + id, this._object2d);
		// }

		// if (Globals.GUI_ENABLED) {
		// 	Globals.GUI.addObject3D('block ' + id, this._block);
		// 	Globals.GUI.addObject3D('rotation container ' + id, this._rotationContainer);
		// }

		this._contentDisplay = new IndexContentDisplay(this._2dContainer, data, this._promoted);

		if (this._promoted) {
			this.initImage();
		}
	}

	public blink = () => {
		gsap.to(this._material.uniforms.saturation, {
			value: 0.35,
			duration: 0.4
		});

		gsap.to(this._material2.uniforms.saturation, {
			value: 0.35,
			duration: 0.4
		});

		gsap.to(this._material.uniforms.saturation, {
			value: 0,
			duration: 0.7,
			delay: 0.4
		});

		gsap.to(this._material2.uniforms.saturation, {
			value: 0,
			duration: 0.7,
			delay: 0.4
		});
	};

	public toggleHover = (state: boolean) => {
		if (this._highlighted) {
			return;
		}

		gsap.to(this._material.uniforms.saturation, {
			value: state ? 0.6 : 0,
			// overwrite: true,
			duration: 1
		});

		gsap.to(this._material2.uniforms.saturation, {
			value: state ? 0.6 : 0,
			// overwrite: true,
			duration: 1
		});
	};

	public updateHelper = () => {
		// if (this._helper) {
		// 	this._helper.update();
		// }
	};

	public setActiveTab = () => {
		this._contentDisplay.setTabIndex(1);
	};

	private initImage = () => {
		let material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			map: Globals.RESOURCE_LOADER.getResource('promotionImage'),
			transparent: true,
			opacity: 0
		});

		let w = 8;
		let h = w * 0.5625;
		let y = h * 0.5;

		let geometry = new THREE.PlaneGeometry(w, h);
		this._image = new THREE.Mesh(geometry, material);
		this._image.position.x = -3.2; //2.6; //-1.5; //-0.5;
		this._image.position.z = 1;
		this._image.position.y = y;
		this._image.rotation.y = degreesToRadians(180);
		this._container.add(this._image);

		// if (Globals.GUI_ENABLED) {
		// 	Globals.GUI.addMesh('Image', this._image);
		// }
	};

	public toggleHighlight = (state: boolean, duration: number) => {
		if (state === this._highlighted) {
			return;
		}

		this._highlighted = state;

		// this._block.castShadow = state;
		// this._block.receiveShadow = !state;

		this._contentDisplay.toggle(state);

		gsap.killTweensOf(this.playHighlightAudio);

		if (state) {
			this._indexMenu.highlightAudio.stop();
			gsap.delayedCall(0.3, this.playHighlightAudio);
		}

		gsap.to(this._highlightPercent, {
			value: state ? 1 : 0,
			duration: duration, //state ? duration : this._promoted ? duration : 0.2, // 0.3
			overwrite: overwrite,
			ease: 'power2.out'
		});

		// gsap.to(this._rotationContainer.rotation, {
		// 	// y: state ? this._promoted ? -0.4 : -1 : 0,
		// 	y: state ? this._promoted ? degreesToRadians(-90) : degreesToRadians(-90) : 0,//this._promoted ? -0.4 : -0.7 : 0,
		// 	duration: duration,//state ? duration : this._promoted ? duration : 0.2, // 0.3
		// 	overwrite: overwrite,
		// 	ease: 'power2.out'
		// });

		gsap.to(this._block.rotation, {
			// y: state ? this._promoted ? -0.4 : -1 : 0,
			y: state ? (this._promoted ? degreesToRadians(-90) : degreesToRadians(-90)) : 0, //this._promoted ? -0.4 : -0.7 : 0,
			duration: duration, //state ? duration : this._promoted ? duration : 0.2, // 0.3
			overwrite: overwrite,
			ease: 'power2.out'
		});

		if (this._glow) {
			gsap.to(this._glow.rotation, {
				// y: state ? this._promoted ? -0.4 : -1 : 0,
				y: state ? (this._promoted ? degreesToRadians(-90) : degreesToRadians(-90)) : 0, //this._promoted ? -0.4 : -0.7 : 0,
				duration: duration, //state ? duration : this._promoted ? duration : 0.2, // 0.3
				overwrite: overwrite,
				ease: 'power2.out'
			});
		}

		gsap.to(this._rotationContainer.position, {
			z: state ? (this._promoted ? -1 : -1) : 0, //-1.5
			// z: state ? -1.5 : 0,
			//x: state ? this._promoted ? 3.5 : -0.5 : 0,
			// x: state ? this._promoted ? 3.2 : 0 : 0,
			duration: duration, //state ? duration : this._promoted ? duration : 0.2, // 0.3
			overwrite: overwrite,
			ease: 'power2.out'
		});

		gsap.to(this._material.uniforms.saturation, {
			value: state ? 1 : 0,
			overwrite: overwrite,
			duration: state ? duration * 3 : duration //state ? duration : 0.2, // 0.3
		});

		gsap.to(this._material2.uniforms.saturation, {
			value: state ? 1 : 0,
			overwrite: overwrite,
			duration: state ? duration * 3 : duration //state ? duration : 0.2, // 0.3
		});

		if (this._promoted) {
			gsap.to(this._image.position, {
				z: state ? 1 : 2,
				duration: duration, //state ? duration : 0.2, // 0.3
				overwrite: overwrite,
				delay: state ? 0.1 : 0,
				ease: 'power2.out'
			});

			gsap.to(this._image.material, {
				opacity: state ? 1 : 0, // 0.85
				duration: duration, //state ? duration : 0.2, // 0.3
				overwrite: overwrite,
				delay: state ? 0.1 : 0,
				ease: 'power2.out'
			});
		}

		if (state) {
			let d = duration * 0.5;

			gsap.to(this._indexMenu.highlightPointLight.color, {
				r: 0,
				g: 0,
				b: 0,
				duration: d,
				overwrite: overwrite
			});

			gsap.to(this._indexMenu.highlightPointLight, {
				intensity: 0,
				duration: d,
				overwrite: overwrite,
				onComplete: this.updateHighlight,
				onCompleteParams: [duration] //[state ? duration : 0.2]
			});
		}
	};

	private playHighlightAudio = () => {
		this._indexMenu.highlightAudio.play();
	};

	private updateHighlight = (duration: number) => {
		// this._indexMenu.highlightPointLight.position.set(this._promoted ? 1.6 : -0.3, 2.6, 0);
		// this._indexMenu.highlightPointLight.target.position.set(0.3, 2.1, 5.7);

		// this._indexMenu.highlightPointLight.position.set(this._promoted ? 1.6 : 0, 2.2, 2.2);

		this._block.add(this._indexMenu.highlightPointLight);
		this._block.add(this._indexMenu.highlightPointLight.target);

		if (Globals.DEBUG) {
			this._block.add(this._indexMenu.hightlightPointLightHelper);
		}

		gsap.to(this._indexMenu.highlightPointLight.color, {
			r: this._colors[0].r,
			g: this._colors[0].g,
			b: this._colors[0].b,
			overwrite: overwrite,
			duration: duration // - 0.1//0.3,
		});

		gsap.to(this._indexMenu.highlightPointLight, {
			duration: duration, // - 0.1,
			overwrite: overwrite,
			intensity: this._glowIntensity * 0.5
		});
	};

	get block() {
		return this._block;
	}

	get container() {
		return this._container;
	}

	get id() {
		return this._id;
	}

	get position() {
		return this._position;
	}

	set position(value: number) {
		this._position = value;
	}

	get offset() {
		return this._promoted ? this._highlightPercent.value * 10 : this._highlightPercent.value * 1.5;
	}

	get link() {
		return this._link;
	}

	get promoted() {
		return this._promoted;
	}
}

import { World } from '../3d/World';

declare class DocumentTouch {}

import { detect } from 'detect-browser';
import Dat from 'dat.gui';
import ResourceLoader from '../loaders/ResourceLoader';
import { MouseInput } from '../input/MouseInput';

export class Globals {
	public static DEBUG: boolean = false;
	public static DISABLE_CONTROLS: boolean = false;
	public static POST_PROCESSING_ENABLED = false;

	public static GUI: Dat.gui;
	public static GUI_COUNT: number = 0;
	public static GUI_ENABLED: boolean = false;

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('App') as HTMLDivElement;
	public static TEMPLATE_LAYER = document.getElementById('TemplateLayer') as HTMLDivElement;
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_IE: boolean = false;

	private static DOCUMENT_TOUCH: DocumentTouch = (window as any).DocumentTouch;
	public static IS_MOBILE: boolean = false;
	public static IS_ANDROID: boolean = navigator.userAgent.toLowerCase().indexOf('android') > -1;
	public static IS_IOS: boolean = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	public static IS_SAFARI: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	public static WORLD: World;
	public static RESOURCE_LOADER: ResourceLoader;

	public static browser = detect();

	public static MOUSE_INPUT: MouseInput = new MouseInput();

	public static MAIN_LOGO: SVGSVGElement;
	public static BP_MD: number = 800;
}

import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {ModifiedBloomPass} from './ModifiedBloomPass';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {FilmPass} from 'three/examples/jsm/postprocessing/FilmPass';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {VignetteShader} from 'three/examples/jsm/shaders/VignetteShader';
import {gsap} from 'gsap';
import {clamp} from '../../../lib/com/hellomonday/utils/MathUtils';
import {Globals} from "../../data/Globals";
import {WindowManager} from "../../utils/WindowManager";

export class PostProcessing {
	private _composer: EffectComposer;
	private _renderPass: RenderPass;
	private _bloomPass: ModifiedBloomPass;
	private _vignettePass: ShaderPass;
	private _renderer: THREE.WebGLRenderer;
	private _clock: THREE.Clock;

	private _scene;
	private _camera;

	private _params = {
		exposure: 0.7,
		bloomStrength: 0.35,
		bloomThreshold: 0,
		bloomRadius: 0,
		vignetteDarkness: 1,
		vignetteOffset: 1.5
	};


	public active: boolean = true;

	private _particleScene: THREE.Scene;

	private _lightAmplification: number = 0;

	constructor(scene: THREE.Scene, camera: THREE.Camera, renderer: THREE.WebGLRenderer) {
		this._camera = camera;
		this._renderer = renderer;
		this._scene = scene;

		this._clock = new THREE.Clock();
		this._clock.start();

		// Render pass
		this._renderPass = new RenderPass(this._scene, camera);
		this._composer = new EffectComposer(renderer);
		this._composer.addPass(this._renderPass);

		// Bloom
		let dimensions = new THREE.Vector2(window.innerWidth, window.innerHeight);
		this._bloomPass = new ModifiedBloomPass(dimensions, 0.25, 2, 0);
		this._bloomPass.threshold = this._params.bloomThreshold;
		this._bloomPass.strength = this._params.bloomStrength;
		this._bloomPass.radius = this._params.bloomRadius;
		// this._composer.addPass(this._bloomPass);

		// resolution, strength, radius, threshold) {

		// Film grain
		let filmPass = new FilmPass(0.15, 0, 0, 0);
		filmPass.renderToScreen = true;
		this._composer.addPass(filmPass);

		// Vignette
		this._vignettePass = new ShaderPass(VignetteShader);
		this._vignettePass.uniforms['offset'].value = this._params.vignetteOffset;
		this._vignettePass.uniforms['darkness'].value = this._params.vignetteDarkness;
		this._vignettePass.renderToScreen = true;
		// this._composer.addPass(this._vignettePass);

		// if (Globals.GUI_ENABLED) {
		// 	this.addGui();
		// }


		window.addEventListener('focus', this.onWindowFocus);
		window.addEventListener('blur', this.onWindowBlur);
	}

	private onWindowFocus = () => {
		this._clock.start();
		this.active = true;
	};

	private onWindowBlur = () => {
		this._clock.stop();
		this.active = false;
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Bloom');
		top.add(this._params, 'exposure', 0, 5)
			.step(0.01)
			.onChange(this.updateBloom)
			.listen();
		top.add(this._params, 'bloomThreshold', 0.0, 3)
			.step(0.01)
			.onChange(this.updateBloom)
			.listen();
		top.add(this._params, 'bloomStrength', 0.0, 10)
			.step(0.01)
			.onChange(this.updateBloom)
			.listen();
		top.add(this._params, 'bloomRadius', 0.0, 5)
			.step(0.01)
			.onChange(this.updateBloom)
			.listen();
		top.add(this, 'active', false, true).name('Enabled');
		top.open();

		top = Globals.GUI.addFolder('Vignette');

		top.add(this._params, 'vignetteDarkness', 1, 5)
			.step(0.01)
			.onChange(this.updateVignette)
			.listen();
		top.add(this._params, 'vignetteOffset', 0, 10)
			.step(0.01)
			.onChange(this.updateVignette)
			.listen();
		top.open();
	};

	get vignetteDarkness() {
		return this._params.vignetteDarkness;
	}

	set vignetteDarkness(value: number) {
		this._params.vignetteDarkness = value;
		this.updateVignette();
	}

	get vignetteOffset() {
		return this._params.vignetteOffset;
	}

	set vignetteOffset(value: number) {
		this._params.vignetteOffset = value;
		this.updateVignette();
	}

	private updateVignette = () => {
		this._vignettePass.uniforms['offset'].value = this._params.vignetteOffset;
		this._vignettePass.uniforms['darkness'].value = this._params.vignetteDarkness;
	};

	get bloomStrength() {
		return this._params.bloomStrength;
	}

	set bloomStrength(value: number) {
		this._params.bloomStrength = value;
		this._bloomPass.strength = value;
	}

	get bloomExposure() {
		return this._params.exposure;
	}

	set bloomExposure(value: number) {
		this._params.exposure = value;
		this._renderer.toneMappingExposure = Math.pow(this._params.exposure, 4.0);
	}

	get bloomRadius() {
		return this._params.bloomRadius;
	}

	set bloomRadius(value: number) {
		this._params.bloomRadius = value;
		this._bloomPass.radius = this._params.bloomRadius;
	}

	get bloomThreshold() {
		return this._params.bloomThreshold;
	}

	set bloomThreshold(value: number) {
		this._params.bloomThreshold = value;
		this._bloomPass.threshold = this._params.bloomThreshold;
	}

	private updateBloom = () => {
		this._renderer.toneMappingExposure = Math.pow(this._params.exposure, 4.0);
		this._bloomPass.threshold = this._params.bloomThreshold;
		this._bloomPass.strength = this._params.bloomStrength + this._lightAmplification;
		this._bloomPass.radius = this._params.bloomRadius;
	};

	public increaseLightAmplification = () => {
		this._lightAmplification = clamp(this._lightAmplification + 0.15, 0, 1.5);
		this.updateBloom();

		gsap.killTweensOf(this.resetLightAmplification);
		gsap.delayedCall(0.5, this.resetLightAmplification);
	};

	private resetLightAmplification = () => {
		gsap.to(this, 0.5, {_lightAmplification: 0, onUpdate: this.updateBloom});
	};

	public reset = () => {
		this._params.exposure = 1;
		this._params.bloomStrength = 1.5;
		this._params.bloomThreshold = 0;
		this._params.bloomRadius = 0;

		this.updateBloom();
	};

	public resize = () => {
		this._composer.setSize(WindowManager.width, WindowManager.height);
	};

	public update = () => {
		if (this.active) {
			let delta = this._clock.getDelta();
			this._composer.render(delta);
		}
	};

	public setCamera = (camera: THREE.PerspectiveCamera) => {
		this._camera = camera;
		this._renderPass.camera = camera;
	};

	get bloomPass(){
		return this._bloomPass
	}
}

import { BASE_PATH } from '../utils/Contants';
//import { introPage } from "../templates/IntroPage";
import { dynamicSort } from '../utils/Helpers';

class ShowData {
	public json: any = { shows: [] };
	public students: Array<any> = [];

	public async load(showId) {
		const loadCheck = this.getKeyByValue(this.json.shows, 'id', showId);

		if (!loadCheck) {
			//@ts-ignore show_id is a var in Main.html.twig that originates from .env file
			const showResponse = await fetch(BASE_PATH + 'assets/data/shows/' + showId + '.json');

			if (showResponse.ok) {
				const response = await showResponse.json();
				const data = this.parseShowsData(response, showId);

				this.json.shows.push({
					id: showId,
					programmes: data['programmes'],
					projects: data['projects'],
					//units: data['units'],
					years: data['years'],
					students: data['students'].sort()
				});
			}
		}
	}

	public getShowFromJson(showId: string) {
		let val = {};
		this.json.shows.forEach(show => {
			if (show['id'] == showId) {
				val = show;
			}
		});
		return val;
	}

	// Checks if value already exists
	private getKeyByValue(object, key, value) {
		return Object.keys(object).find(obj => object[obj][key] === value);
	}

	public getShow(showId: string) {
		return this.json;
	}

	// Select only programmes for either bpro or autumn from bproautumn2020
	private filterException(showsData, showId) {
		let filteredProgrammes = [];
		const bproShows = ['ad', 'ud', 'ac', 'bio'];
		const autumnShows = ['ma', 'mahue'];

		showsData.shows.forEach(show => {
			if (show.slug === 'bproautumn2020') {
				show.programmes.forEach(programme => {
					if (showId === 'autumn' && autumnShows.indexOf(programme.slug) >= 0) {
						filteredProgrammes.push(programme);
					} else if (showId === 'bpro' && bproShows.indexOf(programme.slug) >= 0) {
						filteredProgrammes.push(programme);
					}
				});

				show.programmes = filteredProgrammes;
			}
		});

		return showsData;
	}

	public parseShowsData(showsData, showId) {
		const parsedData = {
			years: [],
			programmes: [],
			units: [],
			projects: [],
			students: []
		};

		if (showId === 'autumn' || showId === 'bpro') {
			showsData = this.filterException(showsData, showId);
		}

		showsData.shows.forEach(show => {
			if (!show.showOnIndex) return;

			// Add years.
			if (!this.getKeyByValue(parsedData['years'], 'year', show.year)) {
				parsedData['years'].push({
					slug: show.slug,
					year: show.year,
					url: show.url
				});
			}

			show.programmes.forEach(programme => {
				if (!this.getKeyByValue(parsedData['programmes'], 'title', programme.title)) {
					// Add programme.
					parsedData['programmes'].push({
						title: programme.title,
						shortTitle: programme.shortTitle,
						qualification: programme.qualification,
						units: programme.units
					});
				} else {
					// Already a programme found from a different year. Skip adding programme.
					// And only add units.
					parsedData['programmes'].forEach(prog => {
						if (prog.title === programme.title) {
							programme.units.forEach(u => {
								if (!this.getKeyByValue(prog.units, 'code', u.code)) {
									prog.units.push(u);
								}
							});
						}
					});
				}

				// Add projects.
				programme.units = programme.units.sort(dynamicSort('code'));
				programme.units.forEach(unit => {
					unit.projects.forEach(project => {
						project['unitCode'] = unit.code;

						// Append 0 for sorting in grid
						if (unit.code.search('UG') > -1 || unit.code.search('Design Studio') > -1 || unit.code.search('RC') > -1) {
							// Gets number from string
							let number = unit.code.match(/\d+/)[0];
							// Append 0
							number = number < 10 ? '0' + number : number;
							// Removes numeric and slash to prepend to new number
							project['unitCode'] = unit.code.replace(/[0-9/]/g, '') + number;
						} else {
							project['unitCode'] = unit.code;
						}

						project['meta'] = {
							year: show.year,
							unitSlug: unit.slug,
							unitCode: unit.code,
							programme: programme.shortTitle,
							programmeTitle: programme.title,
							programmeQualification: programme.qualification,
							baseUrl: show.url
						};

						parsedData['projects'].push(project);

						project.students.forEach(student => {
							if (!this.getKeyByValue(parsedData['students'], 'name', student.name)) {
								parsedData['students'].push(student);
							}
						});
					});
				});
			});
		});

		return parsedData;
	}

	public getFilteredProjects(showId: string, programme: string = '', unit: string = '', year: string = '') {
		const data = showData.getShowFromJson(showId);
		const results = [];

		data['projects'].forEach(project => {
			let match = true;

			if (programme && project['meta']['programme'] !== programme) {
				match = false;
			}

			if (unit && project['meta']['unitCode'].replace('&amp;', '&') !== unit) {
				match = false;
			}

			if (year && project['meta']['year'] !== year) {
				match = false;
			}

			if (match) {
				results.push(project);
			}
		});

		return results;
	}

	public getFilteredProjectsByStudent(showId, studentName) {
		const data = showData.getShowFromJson(showId);
		const results = [];

		data['projects'].forEach(project => {
			project.students.forEach(student => {
				if (student.name === studentName) {
					results.push(project);
				}
			});
		});

		return results;
	}

	public getUnitsFromProgramme(showId, programmeId) {
		let results = [];
		const data = showData.getShowFromJson(showId);
		data['programmes'].forEach(programme => {
			if (programme.shortTitle === programmeId) {
				results = programme.units;
			}
		});

		return results;
	}

	public getUnitsFromShows(showsData) {
		const results = [];

		// this.json.show.programmes.forEach(programme => {
		// 	results.push(programme);
		// });
		return results;
	}

	public getStudents(show: string, name: string = null) {
		const data = showData.getShowFromJson(show);
		const results = [];

		data['students'].forEach(student => {
			const sName = student.name.toLowerCase();
			if (!name || sName.indexOf(name.toLowerCase()) > -1) {
				results.push(student);
			}
		});
		return results;
	}

	public getProjectsByAwardType(awardType: string = null) {
		const results = [];
		const studentsDict = {};
		const projectsDict = {};
		this.getProjectsByProgrammeAndUnit().forEach(project => {
			//add student awards
			project.students.forEach(student => {
				if (student.awards) {
					student.awards.forEach(award => {
						if (!studentsDict[student.name] && (!awardType || award.type.toLowerCase().replace(/&amp;/g, '&') == awardType.toLowerCase())) {
							const projectClone = Object.assign({}, project);
							projectClone.students = [student];
							studentsDict[student.name] = student;
							results.push(projectClone);
						}
					});
				}
			});
			//add project awards
			if (project.awards) {
				project.awards.forEach(award => {
					if (!projectsDict[project.title] && (!awardType || award.type.toLowerCase().replace(/&amp;/g, '&') == awardType.toLowerCase())) {
						projectsDict[project.title] = project;
						results.push(project);
					}
				});
			}
		});
		return results;
	}

	public getProjectsByProgrammeAndUnit(programmeSlug: string = null, unitSlug: string = null) {
		const results = [];
		// this.json.show.programmes.forEach(programme => {
		//   if (
		//     !programmeSlug ||
		//     programme.slug.toLowerCase() == programmeSlug.toLowerCase()
		//   ) {
		//     programme.units.forEach(unit => {
		//       unit.projects.forEach(project => {
		//         if (
		//           !unitSlug ||
		//           unit.slug.toLowerCase() == unitSlug.toLowerCase()
		//         ) {
		//           results.push(project);
		//         }
		//       });
		//     });
		//   }
		// });
		return results;
	}

	public getProjectsByProgrammeAndGroup(programmeSlug: string, groupValue: string) {
		const results = [];
		this.getUnitsByProgramme(programmeSlug).forEach(unit => {
			unit.projects.forEach(project => {
				if (!groupValue || project.groupValue.toLowerCase() == groupValue.toLowerCase()) {
					results.push(project);
				}
			});
		});
		return results;
	}

	public getProgrammesFromProjectsData(projectsData, showId) {
		const programmes = this.getProgrammesByShow(showId);
		const programmesResults = [];
		const check = [];

		programmes.forEach(programme => {
			projectsData.forEach(project => {
				const progTitle = project.meta.programmeTitle;
				if (check.indexOf(progTitle) < 0 && programme.title === progTitle) {
					check.push(progTitle);
					programmesResults.push(programme);
				}
			});
		});
		return programmesResults;
	}

	public getProjectsByProgramme(projectsData, programmeName, year) {
		const results = [];
		projectsData.forEach(project => {
			if (project.meta.programmeTitle === programmeName && project.meta.year === year) {
				results.push(project);
			}
		});
		return results;
	}

	public getProgrammesByShow(showId) {
		return showData.getShowFromJson(showId)['programmes'];
	}

	public getUnitsByProgramme(programmeSlug: string = null) {
		const results = [];
		// this.json.show.programmes.forEach(programme => {
		//   if (
		//     !programmeSlug ||
		//     programme.slug.toLowerCase() == programmeSlug.toLowerCase()
		//   ) {
		//     programme.units.forEach(unit => {
		//       results.push(unit);
		//     });
		//   }
		// });
		return results;
	}

	public getGroupsByProgramme(programmeSlug: string = null) {
		const resultsObj = {};
		this.json.show.programmes.forEach(programme => {
			if (!programmeSlug || programme.slug.toLowerCase() == programmeSlug.toLowerCase()) {
				programme.units.forEach(unit => {
					unit.projects.forEach(project => {
						if (project.groupValue) {
							resultsObj[project.groupValue] = {
								value: project.groupValue,
								name: project.groupName
							};
						}
					});
				});
			}
		});

		const results = [];
		for (let resultsObjKey in resultsObj) {
			results.push(resultsObj[resultsObjKey]);
		}
		return results; //.sort((a, b) => (a.title > b.title) ? 1 : -1)
	}

	public getProjectsByStudent(studentName: string = null) {
		const results = [];
		this.getProjectsByProgrammeAndUnit().forEach(project => {
			project.students.forEach(student => {
				if (!studentName || student.name == studentName) {
					const projectClone = Object.assign({}, project);
					projectClone.students = [student];
					results.push(projectClone);
				}
			});
		});
		return results;
	}
}

export const showData = new ShowData();

import { gsap } from 'gsap';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { CSS2DRenderer } from 'three/examples//jsm/renderers/CSS2DRenderer.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { WindowManager } from '../utils/WindowManager';
import { PostProcessing } from './postprocessing/PostProcessing';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { Globals } from '../data/Globals';
import { clamp } from '../../lib/com/hellomonday/utils/MathUtils';

export class World {
	public renderSignal: Signal = new Signal();

	private _scene: THREE.Scene;
	private _renderer: THREE.WebGLRenderer;
	private _labelRenderer: CSS2DRenderer;

	private _camera: THREE.PerspectiveCamera;

	private _lookAt: THREE.Vector3 = new THREE.Vector3(0, 2.4, -5);

	private _stats = new Stats();

	private _controls;

	private readonly _postProcessing: PostProcessing;

	private _active: boolean = false;

	constructor(canvas: HTMLCanvasElement) {
		this._stats.showPanel(0);
		// document.body.appendChild(this._stats.dom);

		this._scene = new THREE.Scene();
		this._scene.visible = false;

		this._camera = new THREE.PerspectiveCamera(75, WindowManager.width / WindowManager.height, 0.01, 10000);
		//this._camera = new THREE.PerspectiveCamera(40, WindowManager.width / WindowManager.height, 0.01, 10000);

		this._camera.lookAt(this._lookAt);

		this._renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas, alpha: true });
		// this._renderer.outputEncoding = THREE.sRGBEncoding;

		this._renderer.toneMapping = THREE.ReinhardToneMapping;

		this._renderer.shadowMap.enabled = true;
		this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		this._renderer.setPixelRatio(gsap.utils.clamp(1, 3, window.devicePixelRatio));
		this._renderer.setClearColor(0x000000, 0);
		this._renderer.setSize(WindowManager.width, WindowManager.height);

		this._labelRenderer = new CSS2DRenderer();
		this._labelRenderer.setSize(WindowManager.width, WindowManager.height);
		this._labelRenderer.domElement.style.position = 'fixed';
		this._labelRenderer.domElement.style.top = '0';
		this._labelRenderer.domElement.style.left = '0';
		this._labelRenderer.domElement.style.zIndex = '1';
		this._labelRenderer.domElement.classList.add('IndexContentDisplay');
		document.body.appendChild(this._labelRenderer.domElement);

		if (Globals.DEBUG) {
			this._controls = new OrbitControls(this._camera, this._renderer.domElement);
			this._controls.enableZoom = true;
			let world = document.body.querySelector('.World') as HTMLCanvasElement;
			world.style.zIndex = '1000';
			this._labelRenderer.domElement.style.zIndex = '1001';
			this._labelRenderer.domElement.style.pointerEvents = 'none';
			this._controls.enableDamping = true;
		}

		// this._camera.position.set(0, 2.4, 5);
		this._camera.position.set(0, 2.4, 12);

		// if (Globals.GUI_ENABLED) {
		// 	Globals.GUI.addCamera('Camera', this._camera);
		// }

		if (Globals.POST_PROCESSING_ENABLED) {
			this._postProcessing = new PostProcessing(this._scene, this._camera, this._renderer);
			this._postProcessing.setCamera(this._camera);
		}
	}

	public start = () => {
		this._scene.visible = true;
		this._active = true;
		this.render();
	};

	public stop = () => {
		// this._scene.visible = false;
		this._active = false;
	};

	private render = () => {
		// this._stats.begin();

		if (!this._active) {
			return;
		}

		requestAnimationFrame(this.render);

		//this._controls.update();

		this.renderSignal.dispatch();

		this._camera.lookAt(this._lookAt);
		this._camera.updateProjectionMatrix();

		if (Globals.POST_PROCESSING_ENABLED) {
			this._postProcessing.update();
		} else {
			this._renderer.render(this._scene, this._camera);
		}

		this._labelRenderer.render(this._scene, this._camera);

		Globals.RESOURCE_LOADER.mediaMaterial.render();
		// this._stats.end();
	};

	public resize = () => {
		if (Globals.POST_PROCESSING_ENABLED) {
			this._postProcessing.resize();
		}

		let wPerc = clamp(WindowManager.width / 1440, 0, 1);
		let hPerc = clamp(WindowManager.height / 1250, 0, 1);

		this._renderer.setSize(WindowManager.width, WindowManager.height);
		this._labelRenderer.setSize(WindowManager.width, WindowManager.height);

		this._camera.aspect = WindowManager.width / WindowManager.height;

		let fovDistMax = 1700;
		let fovDistMin = 0;
		let dist = clamp(fovDistMax * Math.min(wPerc, hPerc), fovDistMin, fovDistMax);
		let fov = 2 * Math.atan(WindowManager.width / this._camera.aspect / (2 * dist)) * (180 / Math.PI);

		this._camera.fov = fov;

		this._camera.updateProjectionMatrix();
	};

	get renderer() {
		return this._renderer;
	}

	get scene() {
		return this._scene;
	}

	get camera() {
		return this._camera;
	}
}
